<template>
  <v-row>
    <v-col cols="12">
      <v-card class=" shadow rounded-2 m-2">
        <v-card-title>Schedule Table</v-card-title>
        <v-card-text>
          <v-simple-table >
            <template v-slot:default>
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded ">
                <th class="black-text">Date Range</th>
                <th class="black-text">Days</th>
                <th class="black-text">Timing</th>
                <th class="black-text">Trainers</th>
                <th class="black-text">Facility</th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(schedule, index) in schedules"
                  :key="`s_${index}`"
              >
                <td>
                  {{ schedule.start_date | dateformat }} -
                  {{ schedule.end_date | dateformat }}
                </td>
                <td>{{ getWeekdays(schedule.weekdays) }}</td>
                <td>
                  {{ getTimeSlot(schedule) }}
                </td>
                <td>
                  {{ schedule.trainer.map((x) => x.first_name).join(",") }}
                </td>
                <td>
                  {{
                    schedule.facility_name
                        ? schedule.facility_name
                        : schedule.location
                  }}
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class=" shadow rounded-2 m-2">
        <v-card-title>Product and prices</v-card-title>
        <v-card-text>
          <v-simple-table fixed-header>
            <template v-slot:default>
              <thead>
              <tr class="opacity-70 tr-neon tr-rounded ">
                <th width="250" class="black-text">Product</th>
                <th
                    class=""
                    v-if="workshop.workshop_type_id == 1"
                >
                  Duration
                </th>
                <th
                    class="black-text"
                    v-if="workshop.workshop_type_id == 1"
                >
                  Count
                </th>
                <th
                    v-if="workshop.workshop_type_id == 1"
                    class="black-text"
                >
                  Occurrence
                </th>
                <th
                    v-if="workshop.workshop_type_id == 1"
                    class="black-text"
                >
                  Frequency
                </th>
                <th class="black-text">Price (Inc.VAT)</th>
                <th class="black-text">Students</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(product, index) in products" :key="index">
                <td>{{ product.name }}</td>
                <td v-if="workshop.workshop_type_id == 1">
                  {{ product.duration }}
                </td>
                <td v-if="workshop.workshop_type_id == 1">
                  {{ product.count }}
                </td>
                <td v-if="workshop.workshop_type_id == 1">
                  {{ product.occurrence }}
                </td>
                <td v-if="workshop.workshop_type_id == 1">
                  {{ product.frequency }}
                </td>
                <td>
                  {{ product.total_price | toCurrency }}
                </td>
                <td>{{ product.students || 0 }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12">
      <v-card class="mt-6  shadow rounded-2 m-2" >

        <vue-cal
            hide-view-selector
            :time="false"
            view="weekly"
            class="vue-cal-custom"
            style="height: 820px"
            active-view="month"
            :events="events"
            :selected-date="selectedDate"
            @view-change="viewChange($event)"

        >

          <template v-slot:arrow-prev>
            <v-btn fab  small icon
            >
              <ArrowLeft/>
            </v-btn
            >
          </template>
          <template v-slot:arrow-next>
            <v-btn fab  small icon
            >
              <ArrowRight/>
            </v-btn
            >
          </template>
          <template v-slot:cell-content="{ cell, view, events, goNarrower }">
          <span
              class="vuecal__cell-date"
              :class="view.id"
              v-if="view.id === 'day'"
          >
            {{ view.startDate.format("dddd D MMMM (YYYY)") }}
          </span>
            <v-card
                v-if="view.id === 'month'"
                class="pa-2 text-center"
                outlined
                tile
                height="120px"
            >
              <v-btn
                  class="text-decoration-none"
                  x-small
                  text
                  @click="goNarrower"
                  :disabled="cell.outOfScope"
              >{{ cell.startDate | calendarSimpleDate }}</v-btn
              >

              <div
                  v-if="events.length"
                  class="pt-6 d-flex flex-column align-center"
              >
                <v-icon v-if="checkDate(cell.formattedDate)" large color="green"
                >mdi-check-bold</v-icon
                >
                <v-icon v-else large color="black">mdi-minus</v-icon>
                <v-btn
                    @click="viewStudent(cell)"
                    x-small
                    width="50%"
                    elevation="1"
                    dark
                    :color="checkDate(cell.formattedDate) ? 'grey' : 'teal'"
                >View</v-btn
                >
              </div>
            </v-card>
            <span
                class="vuecal__no-event"
                v-else-if="['week', 'day'].includes(view.id) && !events.length"
            >Nothing here</span
            >
            <span class="vuecal__cell-date" :class="view.id" v-else>
            {{ cell.content }}
          </span>
          </template>
        </vue-cal>
      </v-card>
    </v-col>
    <v-col cols="12">

      <v-card class="mt-6" v-if="workshop.documents.length > 0">
        <v-card-title>Documents</v-card-title>
        <v-card-text>
          <v-row>
            <v-col
                md="2"
                sm="2"
                v-for="document in workshop.documents"
                :key="document.id"
            >
              <div
                  align="center"
                  @click="downloadFile(s3BucketURL + document.file_path)"
                  style="cursor: pointer"
              >
                <v-btn x-large text>
                  <v-icon x-large>mdi-file-document</v-icon>
                </v-btn>
                <div class="title">{{ document.name }}</div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-dialog
          v-model="customersLogDialog"
          width="60%"
          @click:outside="showStudent = false"
      >
        <v-card class="fill-height" v-if="!showStudent">
          <v-card-title class="headline-new d-flex justify-space-between">
          <div class="text-left d-flex align-center">
            Schedule Time
            <v-btn
              v-if="this.workshop.workshop_type_id === 2"
              icon
              @click="toggleScheduleStatus"
              class="facility-online-btn"
            >
              <v-icon :color="is_public === 1 ? 'success' : '#df5716'"
                >mdi-lightning-bolt-circle</v-icon
              ></v-btn
            >
          </div>
          <div class="text-left d-flex align-center">
            {{ date | dayDateFormat }}
          </div>
        </v-card-title>
          <v-card-text>
            <v-list subheader v-if="times.length > 0">
              <v-list-item v-for="(time, index) in times" :key="index">
                <v-list-item-content>
                  <v-list-item-title>{{ time.program_name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>{{ time.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title>{{ time.facility_name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-content>
                  <v-list-item-title
                  >{{ time.attendance }} /
                    {{ time.capacity }}</v-list-item-title
                  >
                </v-list-item-content>
              <v-list-item-action
                v-if="
                  workshop.workshop_type_id == 2 &&
                  times[index].is_cancelled == 0
                "
              >
                  <v-btn
                      v-if="!disableAttendance"
                      class="ma-2 white--text teal-color"
                      normal
                      @click="
                    selectedTimeslot = times[index];
                    walkInCustomerFormDialog = true;
                  "
                  >
                    <span>Add Students</span>
                  </v-btn>
                </v-list-item-action>
              <v-list-item-action
                v-else-if="times[index].is_cancelled == 1"
                class="ma-5"
              >
                <span
                  data-v-b74a88c0=""
                  class="red--text"
                  style="font-weight: bold"
                >
                  Class Cancelled.
                </span>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  class="ma-2 white--text teal-color"
                  normal
                  @click="
                    selectedTimeslot = times[index];
                    showStudent = true;
                    timeChange();
                  "
                  >
                    <span>View Students</span>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-card class="fill-height" v-if="showStudent">
          <v-card-title
              class="headline d-flex justify-space-between"
              style="height: 90px"
          >
            <div class="text-left d-flex align-center">Customer Attendance</div>
            <div class="text-left d-flex align-center">{{ programName }}</div>

            <div class="text-left d-flex align-center">
              {{ date | dayDateFormat }}
            </div>

            <div style="width: 220px">
              <v-select
                  class="ma-0 pa-0"
                  v-model="selectedTimeslot"
                  item-text="name"
                  label="Time*"
                  return-object
                  :items="times"
                  item-value="times_id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  width="200"
                  required
                  background-color="#fff"
                  @change="timeChange()"
              >
                <template slot="item" slot-scope="data">
                  <template>
                    <v-list-item-content style="width: 60px">
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.facility_name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action style="width: 30px">
                      {{ data.item.attendance }} /
                      {{ data.item.capacity }}
                    </v-list-item-action>
                  </template>
                </template>
              </v-select>
            </div>
          </v-card-title>
          <v-card-text class="pa-2">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="tableHeader text-center">Name</th>
                  <th class="tableHeader text-center">Mobile</th>
                  <th class="tableHeader text-center">Email</th>
                  <th class="tableHeader text-center">Payment</th>
                  <th class="tableHeader text-center">Status</th>
                  <th class="tableHeader text-center">Attendance</th>
                  <th class="tableHeader text-center">Cancel</th>
                  <th class="tableHeader text-center">Reschedule</th>
                  <th class="tableHeader text-center">Refund</th>
                </tr>
                </thead>
                <tbody v-if="students.length">
                <tr v-for="(student, index) in students" :key="index">
                  <td>{{ student.name }}</td>
                  <td>{{ student.mobile }}</td>
                  <td>{{ student.email }}</td>
                  <td style="text-align: center">
                    <v-btn
                        text
                        color="warning"
                        plain
                        v-if="student.order_status == 5"
                    >Unpaid</v-btn
                    >
                    <v-btn text color="primary" v-if="student.order_status == 4"
                    >Paid</v-btn
                    >
                  </td>
                  <td class="md-table-cell text-center">
                    <v-btn
                        text
                        :color="
                        student.is_attended == 0
                          ? 'secondary'
                          : student.is_attended == 1
                          ? 'success'
                          : 'error'
                      "
                        dark
                        small
                    >
                      {{
                        !student.is_attended
                            ? "NA"
                            : student.is_attended == 1
                                ? "Attended"
                                : student.is_attended == 2
                                    ? "No show"
                                    : ""
                      }}
                    </v-btn>
                  </td>
                  <td class="md-table-cell text-center">
                    <div class="d-flex justify-center">
                      <v-checkbox
                          class="mt-0"
                          v-model="student.is_check"
                          hide-details
                          :indeterminate="
                          student.is_attended === 1 ? true : false
                        "
                        :disabled="
                          student.is_attended === 1 ||
                          selectedTimeslot.is_cancelled == 1
                            ? true
                            : false
                        "
                      ></v-checkbox>
                    </div>
                  </td>
                  <td class="md-table-cell text-center">
                    <div class="d-flex justify-center">
                      <v-checkbox
                        class="mt-0"
                        v-model="student.is_cancel_booking"
                        hide-details
                        :indeterminate="
                          student.is_cancel_booking === 1 ? true : false
                        "
                        :disabled="
                          student.is_cancelled === 1 ||
                          student.is_cancel_booking === 1 ||
                          selectedTimeslot.is_cancelled == 1
                            ? true
                            : false
                        "
                      ></v-checkbox>
                    </div>
                  </td>
                  <td class="md-table-cell text-center">
                    <v-btn
                        v-if="student.is_attended === 0"
                      :disabled="
                        selectedTimeslot.is_cancelled == 1 ? true : false
                      "
                        normal
                        small
                        @click="
                        showRescheduleClassDialog = true;
                        rescheduleStudentDetails = student;
                        refreshRescheduleClass += 1;
                      "
                        @close="
                        showRescheduleClassDialog = false;
                        rescheduleStudentDetails = {};
                      "
                    >Reschedule</v-btn
                    >
                  </td>

                  <td class="md-table-cell" v-if="student.order_status == 4">
                    <v-btn
                      v-if="checkWritePermission($modules.sales.refund.slug)"
                      @click="refund(student)"
                      :disabled="
                        selectedTimeslot.is_cancelled == 1 ? true : false
                      "
                      normal
                      small
                      >Refund</v-btn
                    >
                  </td>
                  <td class="md-table-cell text-center" v-else>NA</td>
                </tr>
                </tbody>
                <tbody v-else>
                <td colspan="9">
                  <v-card-text class="pa-8 text-center">
                    <h3>No students found!</h3>
                  </v-card-text>
                </td>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider></v-divider>
          <!-- !disableReschedule && !checkDate(selectedCellDate) -->
          <v-card-actions>
            <v-btn
            v-if="
              !disableAttendance &&
              !disableReschedule &&
              selectedTimeslot.is_cancelled == 0
            "
                dark
                class="yellow-color"
            @click="
              (rescheduleDialog = true),
                (customersLogDialog = false),
                getTimesByDuration()
            "
            >Reschedule Class</v-btn
            >
            <v-btn
            v-if="
              !disableAttendance &&
              !disableReschedule &&
              selectedTimeslot.is_cancelled == 0
            "
                dark
                class="red-color"
                @click="cancelClass()"
            >Cancel Class</v-btn
            >
          <row md="12" sm="12" v-if="selectedTimeslot.is_cancelled == 1">
            <v-col md="12" sm="12">
              <span
                v-if="selectedTimeslot.is_cancelled == 1"
                data-v-b74a88c0=""
                class="red--text"
                style="font-weight: bold; margin-left: 75px"
              >
                Class Cancelled.
              </span>
            </v-col>
          </row>
          <v-spacer></v-spacer>

          <div
            style="width: 150px; margin-top: 24px; margin-right: 20px"
            v-if="selectedTimeslot.is_cancelled == 0"
          >
              <v-select
                  v-model="selectedTrainer"
                  :items="selectedTimeslot.trainers"
                  label="Choose trainer"
                  item-text="first_name"
                  item-value="trainer_id"
                  outlined
                  dense
                  background-color="#fff"
              ></v-select>
            </div>

          <v-btn
            v-if="selectedTimeslot.is_cancelled == 0"
            dark
            class="red-color"
            @click="cancelBookings()"
            >Cancel Bookings</v-btn
          >

            <v-btn
                dark
                class="blue-color"
                @click="
              customersLogDialog = false;
              showStudent = false;
            "
            >Close</v-btn
            >

            <div style="margin-left: 7px">
              <v-menu
                  offset-y
              v-if="
                students &&
                !students.length == 0 &&
                !disableAttendance &&
                selectedTimeslot.is_cancelled == 0
              "
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="default-color" dark v-bind="attrs" v-on="on">
                    Attendance
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="markAttendance('present')">
                    <v-list-item-title>Mark as attended</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="markAttendance('absent')">
                    <v-list-item-title>Mark no show</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="rescheduleDialog" width="50%">
        <v-card class="fill-height">
          <v-card-title class="headline d-flex justify-space-between">
            <div class="text-left d-flex align-center">Reschedule</div>
            <div class="text-left d-flex align-center">{{ programName }}</div>
            <div class="text-left d-flex align-center">
              {{ date | dayDateFormat }}
            </div>
          </v-card-title>
          <v-card-text>
            <v-row class="mt-0">
              <v-col sm="3" md="4">
                <date-field
                    :backFill="
                  checkBackfillPermission($modules.workshops.schedule.slug)
                "
                    v-model="date"
                    label="Reschedule date"
                >
                </date-field>
              </v-col>
              <v-col lg="3" md="4">
                <v-select
                    v-model="reschedule.venue_service_id"
                    label="Service*"
                    :items="venueServices"
                    item-text="name"
                    item-value="venue_service_id"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    @change="getFacilities($event)"
                    background-color="#fff"
                    :rules="[(v) => !!v || 'Service is required']"
                    required
                ></v-select>
              </v-col>
              <v-col sm="3" md="4">
                <v-select
                    v-model="reschedule.facility_id"
                    item-value="id"
                    item-text="name"
                    :items="reschedule.facilities"
                    label="Facility*"
                    outlined
                    clearable
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    @change="getTimesByDuration"
                />
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col lg="3" md="3">
                <v-select
                    :items="timings"
                    label="Start Time*"
                    item-text="formatted"
                    item-value="time"
                    v-model="reschedule.start_time"
                    :rules="[(v) => !!v || 'Start time is required']"
                    validate-on-blur
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    background-color="#fff"
                    @change="EndTimeValidator()"
                ></v-select>
              </v-col>
              <v-col lg="3" md="3">
                <v-select
                    :readonly="true"
                    :items="timings"
                    label="End Time*"
                    item-text="formatted"
                    item-value="time"
                    v-model="reschedule.end_time"
                    :rules="[(v) => !!v || 'End time is required']"
                    outlined
                    :menu-props="{ bottom: true, offsetY: true }"
                    validate-on-blur
                    background-color="#fff"
                ></v-select>
              </v-col>
              <v-col lg="6" md="6">
                <!-- <v-autocomplete
                  :items="trainersList"
                  label="Trainers*"
                  item-text="name"
                  item-value="id"
                  v-model="reschedule.trainer_id"
                  :rules="[(v) => !!v || 'Trainer is required']"
                  validate-on-blur
                  outlined
                  background-color="#fff"
                >
                </v-autocomplete> -->
                <v-autocomplete
                    :items="trainers"
                    label="Trainers*"
                    item-text="first_name"
                    item-value="trainer_id"
                    v-model="reschedule.trainer_id"
                    :rules="[(v) => !!v || 'Trainer is required']"
                    validate-on-blur
                    outlined
                    background-color="#fff"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark class="blue-color" @click="rescheduleDialog = false"
            >Close</v-btn
            >
            <v-btn dark class="teal-color" @click="rescheduleSlot()"
            >Reschedule</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <reschedule-class
          :refreshRescheduleClass="refreshRescheduleClass"
          :rescheduleClassDialog="showRescheduleClassDialog"
          v-bind="rescheduleStudentDetails"
          :scheduleFromDate="date"
          :programName="programName"
          :programId="programId"
          :selected_time_slot_id="
        this.selectedTimeslot && this.selectedTimeslot.time_slot_id
          ? this.selectedTimeslot.time_slot_id
          : null
      "
          @close="showRescheduleClassDialog = false"
          @reschedule-done="(showRescheduleClassDialog = false), rescheduleDone()"
      ></reschedule-class>

      <confirm-model
          v-bind="confirmModel"
          @confirm="confirmActions"
          @close="confirmModel.id = null"
      ></confirm-model>

      <!-- <refund
        :workshopRefundAmount="workshopRefundAmount"
        :workshopCustomerAttendanceId="workshopCustomerAttendanceId"
        v-if="refund_dialog"
        :show="refund_dialog"
        @close="refund_dialog = false"
        @reload="timeChange"
        @refunded="
          refund_dialog = false;
          timeChange();
        "
      ></refund> -->
      <!-- v-if="refundModel.invoiceId && refundInvoiceData"
         :refundInvoiceData="refundInvoiceData"
         v-bind="refundModel"

        -->
      <RefundNew
          v-if="refundModel.invoiceId && refund_dialog"
          v-bind="refundModel"
          :refundInvoiceData="refundInvoiceData"
          :show="refund_dialog"
          :workshopRefundAmount="workshopRefundAmount"
          :workshopCustomerAttendanceId="workshopCustomerAttendanceId"
          @close="refund_dialog = false"
      @refund="
        refund_dialog = false;
        timeChange();
      "
      />

      <customer-form-for-walk-in
          :selectedCellDate="selectedCellDate"
          :programId="programId"
          :selectedSchedule="selectedTimeslot"
          v-bind="{ workshop: workshop }"
          :walkInCustomerFormDialog="walkInCustomerFormDialog"
          @close="walkInCustomerFormDialog = false"
          @receipt="showReceipt"
      ></customer-form-for-walk-in>

      <order-details
          :id="order_id_for_payment"
          @close="order_id_for_payment = null; viewStudent(lastSelectedCell);"
          @paymentDone="timeChange()"
      ></order-details>
    </v-col>
  </v-row>
</template>

<script>
import OrderDetails from "@/components/Order/OrderDetails.vue";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import moment from "moment";
import RescheduleClass from "./RescheduleClass.vue";
// import refund from "@/components/Order/Refund.vue";
import CustomerFormForWalkIn from "./CustomerFormForWalkIn.vue";
import RefundNew from "@/components/Invoice/RefundNew.vue";
import ArrowLeft from "@/assets/images/misc/arrow-circle-left.svg"
import ArrowRight from "@/assets/images/misc/arrow-circle-right.svg"

export default {
  props: {
    workshop: Object,
    sliderChange: Number,
  },
  data() {
    return {
      lastSelectedCell: null,
      refreshRescheduleClass: 0,
      order_id_for_payment: null,
      walkInCustomerFormDialog: false,
      refund_dialog: false,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      workshopRefundAmount: null,
      workshopCustomerAttendanceId: null,
      rescheduleStudentDetails: {},
      showRescheduleClassDialog: false,
      disableReschedule: false,
      disableAttendance: false,
      selectedWorkshopScheduleId: null,
      timings: [],
      trainersList: [],
      reschedule: {},
      is_public: true,
      date: moment().format("YYYY-MM-DD"),
      selectedDate: moment().format("YYYY-MM-DD"),
      rescheduleDialog: false,
      selectedTrainer: null,
      selectedCellDate: null,
      trainers: [],
      attendanceTrainers: [],
      selectedTimeslot: null,
      times: [],
      students: [],
      availableDays: [],
      events: [],
      products: [],
      schedules: [],
      dateRanges: [],
      customersLogDialog: false,
      showStudent: false,
      programName: null,
      programId: null,
      programDuration: null,
      colors: [
        "blue",
        "indigo",
        "deep-purple",
        "cyan",
        "green",
        "orange",
        "grey darken-1",
      ],
      refundModel: { invoiceId: null, type: "full", amount: 0 },
      refundInvoiceData: {},
      cancelledStatus: false,
    };
  },
  components: {
    "vue-cal": VueCal,
    "reschedule-class": RescheduleClass,
    // refund: refund,
    "customer-form-for-walk-in": CustomerFormForWalkIn,
    OrderDetails,
    RefundNew,ArrowLeft ,ArrowRight
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
  },
  watch: {
    sliderChange(val) {

      if (val == 0 || val > 0) {
        // this.selectedDate = this.workshop.start_date;
        // this.schedules = this.workshop.programs[val].schedules;
        this.dateRanges = this.workshop.programs[val].date_ranges;
        this.products = this.workshop.programs[val].pricing;
        this.programName = this.workshop.programs[val].name;
        this.programId = this.workshop.programs[val].id;
        this.programDuration = this.workshop.programs[val].duration;
        let availableDays = [];
        let scheduleData = [];

        // this.schedules.forEach((schedule) => {
        //   availableDays.push(...schedule.weekdays);
        // });
        // console.log(this.dateRanges);
        this.dateRanges.forEach((dRange) => {
          dRange.schedules.forEach((schedule) => {
            availableDays.push(...schedule.weekdays);
            let obj = schedule;
            obj.start_date = dRange.start_date;
            obj.end_date = dRange.end_date;

            scheduleData.push(obj);
          });
        });

        this.availableDays = [...new Set(availableDays)];
        this.schedules = [...new Set(scheduleData)];
        // console.log("**********");
        // console.log(this.schedules);

        if (!this.$store.getters.getWeekdays.data.length) {
          this.$store.dispatch("loadWeekdays").then(() => {
            this.getEvents();
          });
        } else {
          this.getEvents();
        }
      }
    },
  },
  mounted() {
    if (typeof this.$route.params.data != "undefined") {
      let data = JSON.parse(atob(this.$route.params.data));
      if (data.date) {
        this.lastSelectedCell = {
          formattedDate: data.date,
          id: data.id,
          workshop_schedule_id: data.workshop_schedule_id,
          workshop_program_id: data.workshop_program_id,
        };
        setTimeout(() => {
          this.viewStudent(this.lastSelectedCell);
          this.showStudent = true;
        }, 1000);
      }
    }

    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    this.getTrainers();
  },
  methods: {
    viewChange(ev) {
      if (ev) {
        if (ev.view == "month") {
          this.currentMonthStartDate = moment(ev.startDate).format(
            "YYYY-MM-DD"
          );
          this.currentMonthEndDate = moment(ev.endDate).format("YYYY-MM-DD");
          this.getEvents();
        }
      }
    },

    getTimesByDuration() {
      this.showLoader("Loading facility time...");
      let url = `venues/workshops/time-by-duration?duration=${this.programDuration}&venue_service_id=${this.reschedule.venue_service_id}`;
      if (this.reschedule.facility_id) {
        url += `&facility_id=${this.reschedule.facility_id}`;
      }
      this.$http
        .get(url)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.timings = [];
            this.timings = response.data.data;
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    EndTimeValidator() {
      const start_time = moment(this.reschedule.start_time, "HH:mm:ss");
      this.reschedule.end_time = moment(start_time)
        .add(this.programDuration, "minutes")
        .format("HH:mm:ss");

      const slotIndex = this.timings.findIndex(
        (x) => x.time == this.reschedule.end_time
      );

      if (slotIndex == -1) {
        this.showError("Facility not available this time duration");
        this.reschedule.end_time = null;
      }
    },

    rescheduleSlot() {
      if (moment(this.date).isAfter(this.workshop.end_date)) {
        this.showError("Reschedule date is higher than workshop date");
        return true;
      }
      this.showLoader("Rescheduling ...");
      var formData = new FormData();
      formData.append("to_date", this.date);
      formData.append("from_date", this.selectedCellDate);
      formData.append("end_time", this.reschedule.end_time);
      formData.append("start_time", this.reschedule.start_time);
      formData.append("trainer_id", this.reschedule.trainer_id);
      formData.append("facility_id", this.reschedule.facility_id);
      formData.append("venue_service_id", this.reschedule.venue_service_id);
      formData.append("program_schedule_id", this.selectedWorkshopScheduleId);
      if (this.selectedTimeslot && this.selectedTimeslot.reschedule_id) {
        formData.append("reschedule_id", this.selectedTimeslot.reschedule_id);
      }
      formData.append("current_start_time", this.selectedTimeslot.time);

      this.$http
        .post(`venues/workshops/schedules/booking/reschedule`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.rescheduleDialog = false;
            this.hideLoader();
            this.getEvents();
            this.showStudent = false;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getTrainers() {
      this.$http
        .get(`venues/trainers/short`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.trainersList = response.data.data;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    toggleScheduleStatus() {
      this.showLoader("Please wait...");
      let data = {
        date: this.selectedCellDate,
        program_id: this.programId,
      };
      this.$http
        .post(`venues/workshops/toggle-schedule`, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Toggled successfully");
            this.is_public = response.data.new_status;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFacilities(venueServiceId) {
      if (this.venueServiceId > 0) return;
      this.$http
        .get(`venues/facilities/short?venue_service_id=${venueServiceId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.reschedule.facilities = response.data.data;
            this.getTimesByDuration();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    checkDate(date) {
      return moment(date, "YYYY-MM-DD").isSameOrBefore();
    },

    viewStudent(cell) {
      this.lastSelectedCell = cell;
      this.date = cell.formattedDate;
      this.times = [];
      this.dayName = moment(cell.formattedDate).format("dddd");
      this.selectedCellDate = cell.formattedDate;
      this.disableAttendance = false;
      if (this.programId === null) {
        this.programId = cell.workshop_program_id;
      }
      this.showLoader("Loading...");
      this.$http
        .get(
          `venues/workshops/time_slot?workshop_program_id=${this.programId}&day=${this.dayName}&date=${this.selectedCellDate}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            if (response.data.data.length) {
              this.student = [];
              this.trainers = [];
              this.schedules.forEach((schedule) => {
                schedule.trainer.forEach((trainer) => {
                  let index = this.trainers.findIndex(
                    (x) => x.trainer_id == trainer.trainer_id
                  );
                  if (index == -1) {
                    this.trainers.push(trainer);
                  }
                });
              });
              response.data.data.forEach((element, index) => {
                // console.log("timslot res", element);
                let selectedTrainer = [];
                if (element.trainers && element.is_reschedule === 1) {
                  let schArr = this.trainers.find(
                    (x) => x.trainer_id == element.trainers
                  );
                  if (schArr) {
                    selectedTrainer.push(schArr);
                  }
                } else {
                  let sch = this.schedules.find(
                    (schedule) => schedule.id === element.id
                  );
                  if (sch.trainer.length) {
                    // console.log(sch.trainer);
                    sch.trainer.forEach((trainer) => {
                      selectedTrainer.push(trainer);
                    });
                  }
                }

                this.times.push({
                  name:
                    moment(element.start_time, "HH:mm").format("h:mm a") +
                    " To " +
                    moment(element.end_time, "HH:mm").format("h:mm a"),
                  time: element.start_time,
                  workshopScheduleId: element.id,
                  is_reschedule: element.is_reschedule,
                  from_date: element.from_date,
                  reschedule_id: element.reschedule_id,
                  facility_name: element.facility_name
                    ? element.facility_name
                    : element.location,
                  capacity: element.capacity,
                  attendance: element.attendance,
                  program_name: element.name,
                  venue_service_id: element.venue_service_id,
                  facility_id: element.facility_id,
                  start_date: element.start_date,
                  end_date: element.end_date,
                  time_slot_id: element.time_slot_id,
                  times_id: index + 1,
                  date_range_id: element.date_range_id,
                  trainers: selectedTrainer,
                  is_cancelled: element.is_cancelled,
                });
              });

              let selectedCellDate = moment(this.selectedCellDate);
              let todayDate = moment();
              if (
                todayDate.diff(selectedCellDate, "days") > 1 &&
                !this.checkBackfillPermission(
                  this.$modules.workshops.schedule.slug
                )
              ) {
                this.disableAttendance = true;
              }

              this.selectedTimeslot = this.times[0];
              this.is_public = response.data.is_public;
              this.timeChange();
            }
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    timeChange() {
      const workshopScheduleId = this.selectedTimeslot.workshopScheduleId;
      const weekdays = this.dayName;
      const time = this.selectedTimeslot.time;
      const is_reschedule = this.selectedTimeslot.is_reschedule;
      const from_date = this.selectedTimeslot.from_date;
      this.reschedule.venue_service_id = this.selectedTimeslot.venue_service_id;
      this.getFacilities(this.reschedule.venue_service_id);

      if (this.selectedTimeslot.facility_id) {
        this.reschedule.facility_id = this.selectedTimeslot.facility_id;
        this.getTimesByDuration();
      }

      this.getBookedCustomer(
        workshopScheduleId,
        weekdays,
        time,
        is_reschedule,
        from_date
      );
    },
    rescheduleDone() {
      this.customersLogDialog = false;
      setTimeout(() => {
        this.customersLogDialog = true;
        this.viewStudent(this.lastSelectedCell);
        this.showStudent = false;
      }, 1000);
    },
    getBookedCustomer(
      workshopScheduleId,
      weekdays,
      time,
      is_reschedule,
      from_date
    ) {
      let reschedule_id = null;
      if (this.selectedTimeslot.is_reschedule) {
        reschedule_id = this.selectedTimeslot.reschedule_id;
      }
      this.disableReschedule = false;
      this.selectedWorkshopScheduleId = workshopScheduleId;
      this.showLoader("Loading");
      this.students = [];
      this.$http
        .get(
          `venues/workshops/schedules/booking/customer?workshop_schedule_id=${workshopScheduleId}&weekdays=${weekdays}&start_time=${time}&date=${this.selectedCellDate}&is_reschedule=${is_reschedule}&from_date=${from_date}&reschedule_id=${reschedule_id}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.students = response.data.data;
            if (
              this.students.findIndex(
                (students) => students.is_attended == true
              ) != -1
            ) {
              this.disableReschedule = true;
            }
            this.selectedTrainer = null;
            this.students.forEach((element) => {
              element.is_check = false;
              let selTrainer = null;
              if (element.trainer_id) {
                selTrainer = this.trainers.find(
                  (x) => x.trainer_id == element.trainer_id
                );
                if (selTrainer) {
                  this.selectedTrainer = selTrainer.trainer_id;
                } else {
                  selTrainer = this.trainersList.find(
                    (x) => x.id == element.trainer_id
                  );
                  if (selTrainer) {
                    this.selectedTrainer = selTrainer.id;
                    let obj = {
                      trainer_id: selTrainer.id,
                      first_name: selTrainer.name,
                    };
                    this.trainers.push(obj);
                  }
                }
              }
            });
            this.customersLogDialog = true;

            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    markAttendance(type) {
      if (!this.selectedTrainer) {
        this.showError("Please select trainer");
        return true;
      }

      var data = [];

      this.students.forEach((student) => {
        var obj = {};

        obj = {
          attendance_id: student.attendance_id,
          order_id: student.order_id,
          date: this.selectedCellDate,
          trainer_id: this.selectedTrainer,
          booking_schedule_id: student.workshop_booking_schedule_id,
          workshop_booking_id: student.workshop_booking_id,
          workshop_schedule_id: student.workshop_schedule_id,
        };

        if (student.is_check) {
          if (type == "present") {
            obj.is_attended = student.is_check ? 1 : 0;
          }
          if (type == "absent") {
            obj.is_attended = student.is_check ? 2 : 0;
          }
        }
        if (student.is_check != 0) data.push(obj);
      });

      if (data.length > 0) {
        this.showLoader("Loading...");
        this.$http
          .post(`venues/workshops/schedules/booking/attendance`, {
            attendance: data,
          })
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              // this.customersLogDialog = false;
              // this.students.forEach((el) => {
              //   if (el.is_check) {
              //     if (type == "present") {
              //       el.is_attended = 1;
              //       el.is_check = 0;
              //     }
              //     if (type == "absent") {
              //       el.is_attended = 2;
              //       el.is_check = 0;
              //     }
              //     this.$forceUpdate();
              //   }
              // });
              this.timeChange();

              this.hideLoader();
            }
          })
          .catch((error) => {
            this.customersLogDialog = false;
            this.errorChecker(error);
          });
      }
    },

    downloadFile(path) {
      if (
        this.$store.getters.checkExportPermission(
          this.$modules.workshops.management.id
        )
      ) {
        return;
      }
      window.open(path, "_blank");
    },
    getEvents() {
      if (!this.currentMonthStartDate && !this.currentMonthEndDate) {
        this.currentMonthStartDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        this.currentMonthEndDate = moment().endOf("month").format("YYYY-MM-DD");
      }
      this.showLoader("Loading calender...");
      this.events = [];
      this.$http
        .get(
          `venues/workshops/calender?start_date=${this.currentMonthStartDate}&end_date=${this.currentMonthEndDate}&program_id=${this.programId}&workshop_id=${this.workshop.id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            response.data.data.forEach((el) => {
              this.events.push({
                workshop_program_id: el.workshop_program_id,
                title: this.programName,
                start: moment(el.date).toDate(),
                end: moment(el.date).toDate(),
                class: `${
                  this.colors[this.rnd(0, this.colors.length - 1)]
                } white--text pl-4`,
              });
            });

            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },

    checkAvailableDay(weekdays, start_date) {
      let findValue;
      var isExist = false;
      weekdays.forEach((element) => {
        findValue = this.weekdays.find((y) => y.bit_value == element);
        if (findValue.name == start_date.format("dddd")) {
          isExist = true;
        }
      });
      return isExist;
    },
    getTimeSlot(data) {
      return (
        moment(data.start_time, "HH:mm").format("h:mm a") +
        " To " +
        moment(data.end_time, "HH:mm").format("h:mm a")
      );
    },
    getWeekdays(days) {
      let dayName = "";
      days.forEach((element) => {
        let findValue = this.$store.getters.getWeekdays.data.find(
          (y) => y.bit_value == element
        );
        if (findValue) {
          dayName = dayName.concat(findValue.name.substring(0, 3), ",");
        }
      });
      return dayName.slice(0, -1);
    },

    refund(student) {
      this.confirmModel = {
        id: student.attendance_id,
        title: `Do you want refund this student booking?`,
        description: `This will refund selected student booking. By clicking <b>Yes</b> you can confirm cancel operation`,
        type: "refund",
        data: student,
      };
    },
    cancelClass() {
      this.confirmModel = {
        id: this.selectedWorkshopScheduleId,
        title: `Do you want cancel this class?`,
        description: `By clicking <b>Yes</b> you can confirm cancel operation`,
        type: "cancel",
        data: this.selectedTimeslot,
      };
    },
    cancelBookings() {
      console.log("selectedTimeslot");
      console.log(this.selectedTimeslot);
      console.log(this.selectedWorkshopScheduleId);
      this.confirmModel = {
        id: this.selectedWorkshopScheduleId,
        title: `Do you want cancel this bookings?`,
        description: `By clicking <b>Yes</b> you can confirm cancel operation`,
        type: "cancel_bookings",
        data: this.selectedTimeslot,
      };
    },
    showReceipt(order_id) {
      this.order_id_for_payment = order_id;
    },

    confirmActions(data) {
      if (data.type == "refund") {
        if (data.id && data.data) {
          this.workshopCustomerAttendanceId = data.id;
          this.showLoader("Loading...");

          this.$http
            .get(
              `venues/workshops/schedules/booking/single-class-refund-amount/${data.data.order_id}`
            )
            .then((response) => {
              if (response.status == 200 && response.data.status) {
                this.hideLoader();

                if (
                  response.data.type == "partial" ||
                  response.data.type == "normal"
                ) {
                  this.payments = [
                    {
                      card_type_id: null,
                      payment_code: null,
                      payment_method_id: null,
                      amount: null,
                      payment_method: null,
                    },
                  ];

                  this.$store.commit("setOrderPayments", this.payments);
                  this.workshopRefundAmount = response.data.refund_amount;
                  this.refund_dialog = true;

                  this.refundModel.invoiceId = 1;
                  this.refundModel.amount = response.data.refund_amount;
                  this.refundModel.type = "partial";
                }
                // else if (response.data.type == "normal") {
                //   this.$store
                //     .dispatch("loadOrderDetails", data.data.order_id)
                //     .then((response) => {
                //       if (response.status == 200) {
                //         this.refund_dialog = true;
                //         this.refundModel.invoiceId = 1;
                //         this.refundModel.amount = response.data.refund_amount;
                //         this.refundModel.type = "full";
                //         this.workshopRefundAmount = response.data.refund_amount;
                //       }
                //     });
                // }
              }
            })
            .catch((error) => {
              this.customersLogDialog = false;
              this.errorChecker(error);
            });
        }
      } else if (data.type == "cancel") {
        if (data.id) {
          this.showLoader("Loading...");
          var cancel = {
           workshop_schedule_id: data.id,
           date: this.date,
        };
      this.showLoader("Cancelling class...");
      this.$http
        .post(`venues/workshops/schedules/class-cancel`, cancel)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.selectedTimeslot.is_cancelled=1;
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
        }
      } else if (data.type == "cancel_bookings") {
        if (data.id) {
          this.showLoader("Loading...");

          let cancelledStudents = [];
          this.students.forEach((element) => {
            if (element.is_cancel_booking == true) {
              cancelledStudents.push(element);
            }
          });
          if (cancelledStudents.length == 0) {
            this.showError("Please select at least one customer.");
            this.hideLoader();
            this.$forceUpdate();
          } else {
            var cancel_booking = {
              workshop_schedule_id: data.id,
              date: this.date,
              students: cancelledStudents,
            };
            console.log("cancel Bookings");
            console.log(cancel_booking);
            this.showLoader("Cancelling class...");
            this.$http
              .post(
                `venues/workshops/schedules/class-bookings-cancel`,
                cancel_booking
              )
              .then((response) => {
                if (response.status == 200 && response.data.status == true) {
                  const workshopScheduleId =
                    this.selectedTimeslot.workshopScheduleId;
                  const weekdays = this.dayName;
                  const time = this.selectedTimeslot.time;
                  const is_reschedule = this.selectedTimeslot.is_reschedule;
                  const from_date = this.selectedTimeslot.from_date;
                  this.getBookedCustomer(
                    workshopScheduleId,
                    weekdays,
                    time,
                    is_reschedule,
                    from_date
                  );
                  this.hideLoader();
                  this.$forceUpdate();
                }
              })
              .catch((error) => {
                this.errorChecker(error);
              });
          }
        }
      }
      this.confirmModel.id = null;
    },
  },
};
</script>
<style scoped >
.vue-cal-custom {
  .vuecal__title-bar{
    background : none;
    justify-content: center;
    margin-top: 10px !important;

  }
  .vuecal__title{
    max-width: 200px !important;
    button{
      font-weight: 600;
      font-size: 1.2rem;
    }

  }

  .vuecal__cell{
    .v-btn__content{
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .vuecal__weekdays-headings{
    background: #F8FAFB;
    margin-top: 10px;
    .weekday-label{
      font-size: 0.875rem;
      font-weight: 500;

      .full{
        display: none;
      }
      .small{
        display: block;
      }
    }
  }
}



.headline-new {
  background-color: rgb(233, 241, 246);
  color: black;
}
</style>
