var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3"},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex gap-x-5"},[(_vm.showProductType)?_c('v-select',{staticClass:"q-autocomplete shadow-0",staticStyle:{"max-width":"180px !important"},attrs:{"placeholder":"Product Type","required":"","outlined":"","background-color":"#fff","item-value":"id","item-text":"name","menu-props":{ bottom: true, offsetY: true },"items":_vm.productTypes,"multiple":"","hide-details":"","dense":""},on:{"change":_vm.callGraph},scopedSlots:_vm._u([(_vm.productTypeIds.length === _vm.productTypes.length)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v("All")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"text-elepsis"},[_vm._v(", ..")]):_vm._e()]}}],null,true),model:{value:(_vm.productTypeIds),callback:function ($$v) {_vm.productTypeIds=$$v},expression:"productTypeIds"}}):_vm._e(),(_vm.showVenueService)?_c('v-select',{staticClass:"q-autocomplete shadow-0",staticStyle:{"max-width":"180px !important"},attrs:{"placeholder":"Service","required":"","outlined":"","background-color":"#fff","item-value":"venue_service_id","item-text":"name","menu-props":{ bottom: true, offsetY: true },"items":_vm.venueServices,"multiple":"","hide-details":"","dense":""},on:{"change":_vm.callGraph},scopedSlots:_vm._u([(_vm.venueServiceIds.length === _vm.venueServices.length)?{key:"selection",fn:function(ref){
var index = ref.index;
return [(index === 0)?_c('span',[_vm._v("All")]):_vm._e()]}}:{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index === 0)?_c('span',{staticClass:"text-elepsis"},[_vm._v(_vm._s(item.name))]):_vm._e(),(index === 1)?_c('span',{staticClass:"text-elepsis"},[_vm._v(", ..")]):_vm._e()]}}],null,true),model:{value:(_vm.venueServiceIds),callback:function ($$v) {_vm.venueServiceIds=$$v},expression:"venueServiceIds"}}):_vm._e()],1),_c('div',{staticClass:"d-flex gap-x-5"},[_c('v-btn-toggle',{staticClass:"q-tabs shadow-0 bordered",staticStyle:{"height":"40px !important"},attrs:{"borderless":"","mandatory":_vm.mandatory,"tile":""},model:{value:(_vm.dateToggle),callback:function ($$v) {_vm.dateToggle=$$v},expression:"dateToggle"}},[_c('v-btn',{attrs:{"value":"DATE","height":"40"}},[_vm._v("Day")]),_c('v-btn',{attrs:{"value":"MONTH","height":"40"}},[_vm._v("Month")]),_c('v-btn',{attrs:{"value":"YEAR","height":"40"}},[_vm._v("Year")])],1)],1),_c('date-range-field',{attrs:{"dateType":_vm.dateToggle,"date1":_vm.date1,"date2":_vm.date2,"class-name":"q-text-field shadow-0","outlined":true,"dense":true},on:{"periodChange":_vm.dateChange}})],1)]),(_vm.showProductType)?_c('v-col',{attrs:{"cols":"12"}},[(_vm.showProductType)?_c('v-radio-group',{staticStyle:{"margin-top":"-10px"},attrs:{"row":"","label":"Stack type"},model:{value:(_vm.stack),callback:function ($$v) {_vm.stack=$$v},expression:"stack"}},[_c('v-radio',{attrs:{"label":"All","value":"all"}}),_c('v-radio',{attrs:{"label":"Product Type","value":"type"}}),_c('v-radio',{attrs:{"label":"Service","value":"service"}}),_c('v-radio',{attrs:{"label":"Sales Channel","value":"source"}})],1):_vm._e()],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('BarChart',_vm._b({attrs:{"exportPermission":_vm.exportPermission}},'BarChart',_vm.bookings,false))],1),_c('v-col',{attrs:{"cols":"12"}},[_c('BarChart',_vm._b({attrs:{"exportPermission":_vm.exportPermission}},'BarChart',_vm.sales,false))],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }