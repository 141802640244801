<template>
  <v-dialog
    v-model="walkInCustomerFormDialog"
    scrollable
    persistent
    width="760px"
  >
    <v-form ref="form">
      <v-card>
        <v-card-text class="">
          <div class="row pt-1">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text=" Customer" style="color: black"></SvgIcon>
                <div class="d-flex gap-x-1">
                  <v-btn  fab x-small class="shadow-0"  @click="close" >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <v-divider class="mt-2" />
          <v-row class="mt-2" dense>
            <v-col sm="6">
              <v-select
                label="Please select product*"
                v-model="selectedWorkshopProductId"
                :rules="[(v) => !!v || 'Product is required']"
                :items="products"
                item-text="name"
                item-value="product_id"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                required
                :readonly="editFlag"
                background-color="#fff"
                @change="totalPriceCalculation"
                class="q-autocomplete shadow-0"
                hide-details="auto"
                dense
              >
                <template slot="item" slot-scope="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="d-flex justify-space-between">
                          <div>
                            {{ data.item.name }}
                          </div>
                          <div>
                            {{ (data.item.price + data.item.tax) | toCurrency }}
                          </div>
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col sm="6">
              <v-text-field
                label="Price"
                readonly
                v-model="totalPrice"
                outlined
                :disabled="true"
                background-color="#fff"
                :prefix="currencyCode"
                class="q-text-field shadow-0"
                hide-details="auto"
                dense
              >
              </v-text-field>
            </v-col>
            <v-col
              sm="6"
              v-if="checkReadPermission($modules.salesTeam.management.slug)"
              class="pb-0 pt-0"
            >
              <v-select
                v-model="registerForm.sales_team_id"
                label="Sales Team"
                :items="salesTeams"
                item-text="name"
                item-value="id"
                outlined
                class="q-text-field shadow-0"
                hide-details="auto"
                dense
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
              ></v-select>
            </v-col>
          </v-row>
          <v-row class="mt-2" >
            <div class="col-md-12">
              <div class="d-flex align-center justify-space-between">
                <div class="font-semibold text-base text-blue">Customer details</div>
              </div>
            </div>
          </v-row>
          <v-card class="bordered shadow-0 mt-2">
            <v-card-text>
              <v-row  dense class="mb-4"   v-if="!orderId">
                <v-col md="12">
                  <div class="d-flex justify-space-between" >
                    <div class="d-flex" >
                      <card-reader-button
                          className=" mt-5 text-blue text-capitalize light-blue-color"

                          label="Samsotech Reader"
                          docType="chip"

                          @data="
                          (data) => {
                            setCardData(data);
                          }
                        "
                      ></card-reader-button>
                      <card-data-button
                          className="mt-5 ml-4 text-blue text-capitalize light-blue-color"
                          label="HID Omnikey"
                          @data="
                          (data) => {
                            setCardData(data);
                          }
                        "
                      ></card-data-button>
                    </div>


                  </div>
                </v-col>


              </v-row>

              <v-row dense align="center" >
                <v-col md="3">
                  <div style="aspect-ratio: 1/1 ">
                    <image-uploader
                        @upload="
                          (data) => {
                             registerForm.image = data;
                          }
                        "
                        @remove="
                          () => {
                            registerForm.image = null;
                          }
                        "
                        :image_path="registerForm.image_path"
                        :height="240"
                        defaultImage="user"
                        :show-guide="false"
                        message-text=""
                        text=""
                    ></image-uploader>
                  </div>
                </v-col>
                <v-col md="9">
                  <v-row dense>
                    <v-col md="12">
                      <label>Mobile Number*</label>
                      <v-mobile-search
                          v-model="registerForm.search"
                          :selected="registerForm.mobile"
                          @updateCustomer="setCustomerData($event)"
                          ref="mobile"
                          hide-details="auto"
                          class-name1="q-text-field shadow-0"
                          class-name2="q-text-field shadow-0 mobile_auto_complete_hide_anchor"
                          background-color=""
                          :dense="true"
                          label=""
                          :show-label="false"
                          :dial-code-grid-size="3"
                      ></v-mobile-search>
                    </v-col>

                    <v-col md="12">
                      <label>Customer Name*</label>
                      <v-name-search
                          :selected="registerForm.name"
                          :mobile="registerForm.mobile"
                          :email="registerForm.email"
                          v-model="registerForm.nameSearch"
                          @updateCustomer="setCustomerData($event)"
                          class-name="q-text-field shadow-0"
                          :dense="true"
                          hide-details="auto"
                          label=""
                      ></v-name-search>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row dense>


                <v-col cols="12" sm="6" md="6">
                  <label>Email*</label>

                  <v-text-field
                      v-model="registerForm.email"
                      outlined
                      background-color="#fff"
                      required
                      :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                      validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col md="6">
                  <label>Date Of Birth</label>
                  <date-of-birth-field v-model="registerForm.dob"  :dense="true" > </date-of-birth-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>Nationality</label>

                  <v-autocomplete
                      v-model="registerForm.country_id"
                      :items="countries"
                      item-value="id"
                      item-text="name"
                      outlined
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      validate-on-blur
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>Gender</label>

                  <v-select
                      v-model="registerForm.gender"
                      :items="['Male', 'Female']"
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      validate-on-blur
                  ></v-select>
                </v-col>
                <v-col sm="12" md="6">
                  <label>Tags</label>
                  <v-select
                      :items="tags"
                      :placeholder="`Tags`"
                      outlined
                      item-value="id"
                      item-text="name"
                      :menu-props="{ bottom: true, offsetY: true }"
                      v-model="registerForm.customer_tag"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      validate-on-blur
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <label>Document</label>
                  <v-file-input
                      :label="registerForm.document.id != null ? '' : 'Document'"
                      v-model="registerForm.document.file"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon
                      outlined
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                  >
                    <template v-slot:label>
                      <span v-if="!registerForm.document.id">
                        Select Document file
                      </span>
                      <span
                          v-if="
                          registerForm.document.id &&
                            !registerForm.document.file
                        "
                          class="font-weight-bold"
                      >
                        <span
                            style="width: 70%; display: inline-block"
                            class="text-truncate"
                        >{{ registerForm.document.original_file_name }}</span
                        >
                        <span
                            style="width: 20%; display: inline-block"
                            class="text-truncate"
                        >.{{
                            registerForm.document.original_file_name.split(".")[
                            registerForm.document.original_file_name.split(
                                "."
                            ).length - 1
                                ]
                          }}</span
                        >
                      </span>
                    </template>
                    <template v-slot:prepend-inner>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                              color="cyan"
                              v-if="
                              registerForm.document.id &&
                                !registerForm.document.file
                            "
                              @click="openFile(registerForm.document.file_path)"
                              v-on="on"
                          >
                            mdi-download-box
                          </v-icon>
                          <v-icon v-else v-on="on">mdi-image</v-icon>
                        </template>
                        <span v-if="registerForm.document.file_path">
                          Download image</span
                        >
                        <span v-else>Upload Image</span>
                      </v-tooltip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="12">
                  <div class="d-flex gap-x-5">
                    <span class="d-flex align-center"><v-checkbox :ripple="false"  v-model="registerForm.opt_marketing"/> Opt In Marketing</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <div v-if="workshop.documents.length > 0" class="titles pt-2">
            Documents
          </div>
          <v-card
            outlined
            class="mb-4"
            :style="cardStyle"
            v-if="workshop.documents.length > 0"
          >
            <v-card-text>
              <v-row>
                <v-col
                  md="2"
                  sm="2"
                  v-for="document in workshop.documents"
                  :key="document.id"
                >
                  <div
                    align="center"
                    @click="openFile(document.file_path)"
                    style="cursor: pointer"
                  >
                    <v-btn large text>
                      <v-icon large>mdi-file-document</v-icon>
                    </v-btn>
                    <div class="title">{{ document.name }}</div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2 white--text blue-color" @click="close"
            >Close</v-btn
          >
          <v-btn @click="save" class="ma-2 white--text teal-color">{{
            editFlag ? "Update" : "Save"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import DateOfBirthField from "@/components/Fields/DateOfBirthField.vue";
export default {
  components: {SvgIcon,ImageUploader,DateOfBirthField},
  props: {
    walkInCustomerFormDialog: {
      type: Boolean,
      default: false,
    },
    "order-id": { type: Number, default: null },
    workshop: Object,
    selectedSchedule: Object,
    programId: { type: Number, default: null },
    selectedCellDate: { type: String, default: null },
  },
  data() {
    return {
      totalPrice: null,
      workshopPrograms: [],
      products: [],
      workshopBookingId: null,
      registerForm: { document: { id: null } },
      editFlag: false,
      programSelected: {},
      selectedWorkshopProductId: null,
      startDate: moment().format("YYYY-MM-DD"),
      isEmiratesIdCheck: false,
      package_type: 1,
    };
  },
  watch: {
    walkInCustomerFormDialog: {
      immediate: true,
      handler(val) {
        if (val) {
          this.reset();
          if (this.orderId) {
            this.getOrderDetails();
          }
          this.programChange();
          this.selectedWorkshopProductId = null;
        }
      },
    },
    workshop(val) {
      if (val) {
        this.workshopPrograms = val.programs;
      }
    },
  },
  mounted() {
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    this.checkPermission = this.checkExportPermission(
      this.$modules.salesTeam.dashboard.slug
    );
    if (this.checkPermission) {
      this.$store.dispatch("loadSalesTeams", "Academy");
      this.$forceUpdate();
    }
  },
  computed: {
    countries() {
      return this.$store.getters.getCountries.data;
    },
    weekdays() {
      return this.$store.getters.getWeekdays.data;
    },
    promotions() {
      return this.$store.getters.getPromotions.data;
    },
    tags() {
      return this.$store.getters.getTags.data;
    },
    salesTeams() {
      return this.$store.getters.getSalesTeams.data;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },

    reset() {
      this.editFlag = false;
      this.registerForm = { document: { id: null } };
      this.selectedWorkshopProductId = null;
      this.products = [];

      setTimeout(() => {
        // this.$refs["image_upload"].cancel();
        this.$refs.form.resetValidation();
      });
    },

    async getOrderDetails() {
      this.showLoader("Loading");
      await this.$http
        .get(`venues/workshops/schedules/booking/${this.orderId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.editFlag = true;
            const data = response.data.data;
            this.workshopBookingId = data.workshop_order.id;
            this.selectedWorkshopProductId = data.product_id;
            this.selectedCellDate = data.workshop_order.start_date;
            this.totalPriceCalculation();
            setTimeout(() => {
              this.setCustomerData(data.workshop_order.customer);
            }, 0);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    programChange() {
      this.products = [];
      let program = this.workshop.programs.find((x) => x.id == this.programId);
      this.products = program.pricing;
    },
    setCardData(data) {
      this.setCustomerData(data);
    },
    setCustomerData(data) {
      if (data.isEmiratesIdCheck) {
        this.isEmiratesIdCheck = true;
      }
      if (data.mobile && data.first_name && data.customer_id) {
        this.isEmiratesIdCheck = false;
      }
      if (!data.customer_id) {
        this.$set(this.registerForm, "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.registerForm, "name", data.first_name);
      }

      if (
        this.registerForm.customer_id &&
        !data.customer_id &&
        this.registerForm.name != data.name &&
        this.registerForm.mobile != data.mobile
      ) {
        this.$set(this.registerForm, "mobile", null);
        this.registerForm.search = null;
        this.registerForm.nameSearch = null;
        this.$set(this.registerForm, "email", null);
        this.$set(this.registerForm, "gender", null);
        this.$set(this.registerForm, "name", null);
        this.$set(this.registerForm, "customer_id", null);
        this.$set(this.registerForm, "first_name", null);
        this.$set(this.registerForm, "image_path", null);
        this.$set(this.registerForm, "dob", null);
        this.$set(this.registerForm, "country_id", null);
        this.$set(this.registerForm, "customer_tag", null);
        this.$set(this.registerForm, "last_name", null);
        this.$forceUpdate();
      }

      if (data.mobile) this.$set(this.registerForm, "mobile", data.mobile);
      if (data.email) this.$set(this.registerForm, "email", data.email);
      if (data.country_id) {
        this.$set(this.registerForm, "country_id", data.country_id);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.registerForm, "country_id", null);
        }
      }
      if (data.gender) {
        this.$set(this.registerForm, "gender", data.gender);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.registerForm, "gender", null);
        }
      }
      if (data.dob) {
        this.$set(this.registerForm, "dob", data.dob);
      } else {
        if (!this.isEmiratesIdCheck) {
          this.$set(this.registerForm, "dob", null);
        }
      }
      if (data.name) this.$set(this.registerForm, "name", data.name);
      if (data.last_name) {
        this.$set(this.registerForm, "last_name", data.last_name);
      } else {
        this.$set(this.registerForm, "last_name", null);
      }
      if (data.customer_tag) {
        this.$set(this.registerForm, "customer_tag", data.customer_tag);
      } else {
        this.$set(this.registerForm, "customer_tag", null);
      }
      if (data.first_name)
        this.$set(this.registerForm, "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.registerForm, "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.registerForm, "image_path", data.image_path);
      } else {
        this.$set(this.registerForm, "image_path", null);
      }
      if (data.opt_marketing) {
        if (data.opt_marketing == 1) {
          this.$set(this.registerForm, "opt_marketing", true);
        } else {
          this.$set(this.registerForm, "opt_marketing", false);
        }
      }
      this.$forceUpdate();
    },

    verifyBenefit(type) {
      if (!this.selectedWorkshopProductId) {
        this.showError("Please select the product");
        return;
      }
      let data = {
        products: [],
      };
      var workshopProduct = this.products.find(
        (x) => x.product_id == this.selectedWorkshopProductId
      );

      workshopProduct.workshop_id = this.workshop.id;
      workshopProduct.quantity = 1;
      if (type == "promotion") {
        data.promotion_code = this.registerForm.promotion_code;
        if (data.promotion_code == null) {
          return;
        }
      } else {
        data.card_number = this.registerForm.card_number;
      }
      if (this.registerForm.customer_id) {
        data.customer_id = this.registerForm.customer_id;
      }

      data.products = [workshopProduct];
      let url = "venues/benefits/verify";
      this.$http
        .post(url, data)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            if (data.discount) {
              this.workshopProduct = data.products[0];
              this.workshopProduct.discount = data.discount;
              this.workshopProduct.price = data.price;
              this.$forceUpdate();
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    totalPriceCalculation() {
      this.totalPrice =
        this.products.find(
          (x) => x.product_id == this.selectedWorkshopProductId
        ).price +
        this.products.find(
          (x) => x.product_id == this.selectedWorkshopProductId
        ).tax;
    },

    save() {
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      let day = null;
      if (!this.editFlag && this.selectedSchedule.is_reschedule) {
        day = this.weekdays.find(
          (y) =>
            y.name == moment(this.selectedSchedule.from_date).format("dddd")
        );
      } else {
        day = this.weekdays.find(
          (y) => y.name == moment(this.selectedCellDate).format("dddd")
        );
      }

      let formData = new FormData();
      formData.append("workshop_id", this.workshop.id);
      formData.append("workshop_type_id", this.workshop.workshop_type_id);
      formData.append("workshop_program_id", this.programId);
      formData.append("product_id", this.selectedWorkshopProductId);
      formData.append("venue_service_id", this.workshop.venue_service_id);
      formData.append("walkin_form", 1);
      if (this.editFlag) {
        formData.append("order_id", this.orderId);
        formData.append("workshop_booking_id", this.workshopBookingId);
      } else {
        formData.append(`date_ranges[0][date_range_id]`,this.selectedSchedule.date_range_id);
        formData.append(`date_ranges[0][days][0][bit_value]`, day.bit_value);
        formData.append(`date_ranges[0][days][0][workshop_schedule_id]`,this.selectedSchedule.workshopScheduleId);
        formData.append(`date_ranges[0][days][0][start_date]`,this.selectedSchedule.start_date);
        formData.append(`date_ranges[0][days][0][end_date]`,this.selectedSchedule.end_date);
      }

      for (let key in this.registerForm) {
        if (
          this.registerForm[key] &&
          typeof this.registerForm[key] != "object"
        ) {
          formData.append(key, this.registerForm[key]);
        } else if (key == "document" && this.registerForm[key].file) {
          formData.append(key, this.registerForm[key].file);
          if (this.registerForm[key].id) {
            formData.append("document_id", this.registerForm[key].id);
          }
        }
      }
      if (!this.editFlag && this.selectedSchedule.is_reschedule) {
        formData.append("start_date", this.selectedSchedule.from_date);
      } else {
        formData.append("start_date", this.selectedCellDate);
      }
      // this.showLoader("Saving customer..");
      this.$http
        .post(`venues/workshops/schedules/booking`, formData, {
          headers: {
            "Content-Type": "multipart/form-data; boundary=${form._boundary}",
          },
        })
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.hideLoader();
            this.$emit("receipt", response.data.data.order_id);
            this.close();
            if (this.editFlag) this.showSuccess("Update successfully");
            else this.showSuccess("Created schedule successfully");
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style></style>
