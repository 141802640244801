<template>
  <div class="md-card md-theme-default mt-8 shadow rounded-5">
    <div class="md-card-content md-card-table">
      <v-row>
        <v-col md="12" class="text-right">
          <v-btn
            v-if="checkExportPermission($modules.clients.customers.slug)"
            class="export-button mb-2"
            elevation="0"
            height="40"
            @click="exportData"
          >
            <SvgIcon text="Export Report">
              <template v-slot:icon>
                <ExportIcon />
              </template>
            </SvgIcon>
          </v-btn>
        </v-col>
      </v-row>
      <div>
        <v-spacer></v-spacer>
        <div class="table-responsive">
          <table class="table border-collapse">
            <thead class="">
              <tr class="opacity-70 tr-neon tr-rounded">
                <th>
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Name</div>
                    <div class="search_column">
                      <v-autocomplete
                        solo
                        filled
                        label="Name"
                        v-model="searchParams.name"
                        item-value="name"
                        item-text="name"
                        :items="names"
                        :loading="isLoading"
                        :search-input.sync="name"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Mobile</div>
                    <div class="search_column">
                      <v-autocomplete
                        solo
                        filled
                        label="Mobile"
                        v-model="searchParams.mobile"
                        item-value="mobile"
                        item-text="mobile"
                        :items="phones"
                        :loading="isLoading"
                        :search-input.sync="phone"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Email</div>
                    <div class="search_column">
                      <v-autocomplete
                        solo
                        filled
                        label="Email"
                        v-model="searchParams.email"
                        item-value="email"
                        item-text="email"
                        :items="emails"
                        :loading="isLoading"
                        :search-input.sync="email"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Customer Level</div>
                    <div class="search_column">
                      <v-select
                        :items="workshopLevels"
                        item-text="name"
                        item-value="id"
                        label="Level"
                        v-model="searchParams.level_id"
                        solo
                        filled
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                      >
                        <template slot="item" slot-scope="data">
                          <template v-if="!(typeof data.item == 'object')">
                            <v-list-item-content style="text-align: center">
                              {{ data.item }}
                            </v-list-item-content>
                          </template>
                          <template v-else>
                            <v-list-item-content>
                              <v-list-item-title
                                :style="`border-right: 20px solid ${data.item.color_code};`"
                              >
                                {{ data.item.name }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Program Name</div>
                    <div class="search_column">
                      <v-autocomplete
                        solo
                        filled
                        label="Program name"
                        v-model="searchParams.program_name"
                        item-value="name"
                        item-text="name"
                        :items="program_names"
                        :loading="isLoading"
                        :search-input.sync="program_name"
                        @change="getFilterData"
                        class="q-autocomplete shadow-0"
                        flat
                        hide-details
                      ></v-autocomplete>
                    </div>
                  </div>
                </th>
                <th>
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Documents</div>
                  </div>
                </th>
                <th >
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Schedules</div>
                  </div>
                </th>
                <th>
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Receipt</div>
                  </div>
                </th>
                <th>
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Edit</div>
                  </div>
                </th>
                <th>
                  <div class="md-table-head-container md-ripple md-disabled">
                    <div class="md-table-head-label">Notes</div>
                  </div>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                class="border-bottom"
                v-for="customer in customerList"
                :key="customer.order_id"
              >
                <td>
                  <span
                    class="text_ellipse text-neon text-base pointer font-bold"
                    @click="showUserModel(customer.customer_id)"
                    >{{ customer.name }}
                  </span>
                </td>
                <td>
                  <div class="text-truncate" style="max-width: 200px">
                    {{ customer.mobile }}
                  </div>
                </td>
                <td>
                  <div class="text-truncate" style="max-width: 200px">
                    {{ customer.email }}
                  </div>
                </td>

                <td>
                  <div>
                    <v-chip class="ma-2" dark :color="customer.color_code">
                      {{ customer.customer_level || "NA" }}
                    </v-chip>
                  </div>
                </td>
                <td>
                  <div>
                    {{ customer.product_name || "NA" }}
                  </div>
                </td>
                <td>
                    <div>
                      <v-btn
                        v-if="customer.documents && !customer.document_id"
                        normal
                        small
                        @click="showDocuments(customer)"
                        >Documents</v-btn
                      ><v-btn
                        v-if="customer.document && customer.document_id"
                        normal
                        small
                        @click="showDocuments(customer)"
                        >Documents</v-btn
                      >
                    </div>
                  </td>
                <td>
                  <div>
                    <v-btn normal small @click="getCustomerSchedules(customer)"
                      >Schedules</v-btn
                    >
                    <span
                      class="extra-classing"
                      v-if="customer.package_type == 2"
                    >
                      {{ customer.remaining_classes }} /
                      {{ customer.no_of_class }}
                      <v-btn
                        x-small
                        text
                        @click="viewSessionForm(customer.order_id)"
                        :disabled="customer.remaining_classes == 0"
                      >
                        <ScheduleIcon />
                      </v-btn>
                    </span>
                  </div>
                </td>
                <td>
                  <div>
                    <v-btn
                      @click="getOrderDetails(customer)"
                      class="ma-2 white--text teal-color"
                      text
                      normal
                      small
                    >
                      {{ customer.order_status_id == 5 ? "Pay" : "Receipt" }}
                    </v-btn>
                  </div>
                </td>
                <td>
                  <v-tooltip bottom v-if="customer.order_status_id == 5">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-if="
                          customer.attendance_count == 0 ||
                          customer.attendance_count == null
                        "
                        v-bind="attrs"
                        v-on="on"
                        @click="editBooking(customer.order_id)"
                        color="#66c8c8"
                        >mdi-pencil</v-icon
                      >
                      <span v-else>Attended</span>
                    </template>
                    <span>Edit customer</span>
                  </v-tooltip>
                </td>
                <td>
                  <div>
                    <v-btn normal small @click="openNotes(customer)"
                    >Notes</v-btn
                    >
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <v-row>
      <v-col cols="4"></v-col>
      <v-col cols="4">
        <v-pagination v-model="page" :length="totalPages" class="new-pagination" total-visible="7"></v-pagination>
      </v-col>
      <v-col class="d-flex align-center justify-end" cols="4">
        <div class="d-flex align-center justify-end text-sm gap-x-2">
                <span class="">
                  Result
                </span>
          <div style="width: 80px">
            <v-select
                v-model="perPage"
                :items="[10, 15, 25, 50]"
                :menu-props="{ bottom: true, offsetY: true }"
                class="q-autocomplete text-sm"
                flat
                hide-details
                solo
                @change="getWorkshopBookingByProgramId"
            ></v-select>
          </div>
          <span>Per Page</span>
        </div>
      </v-col>
    </v-row>
    <customer-model
      @orderAction="customerModelActions"
      v-bind="userModel"
      @close="userModel.userID = null"
    />

    <v-dialog
      v-model="customerScheduleDialoge"
      width="900"
      persistent
      scrollable
      @input="$emit('close')"
    >
      <v-card>
        <v-card-title class="headline">Schedules</v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters style="margin-top: -20px">
              <table class="history_table">
                <thead>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                  <th>Attendence</th>
                </thead>

                <tr
                  v-for="(data, index) in customerScheduleData"
                  :key="index"
                  class="text-center"
                >
                  <td>{{ data.date | dayDateFormat }}</td>
                  <td>{{ data.start_time }}</td>
                  <td>{{ data.end_time }}</td>
                  <td>{{ data.attendence }}</td>
                </tr>
              </table>
              <v-card-text
                v-if="customerScheduleData && customerScheduleData.length == 0"
                class="pa-8 text-center"
              >
                <h3>No Schedules found!</h3>
              </v-card-text>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color=" darken-1"
            class="ma-2 white--text blue-color"
            text
            @click="
              () => {
                customerScheduleDialoge = false;
              }
            "
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <customer-form-for-walk-in
      :programId="programId"
      :order-id="orderId"
      v-bind="{ workshop: workshop }"
      :walkInCustomerFormDialog="walkInCustomerFormDialog"
      @close="walkInCustomerFormDialog = false"
    ></customer-form-for-walk-in>
    <CustomerNotes
        :notes="notes"
        :notes-dialogue="notesDialogue"
        :workshop-booking-id="notesBookingId"
        :customer-id="notesCustomerId"
        @closeDialogue="closeDialogue"
        @refreshData="refreshBookings"
      />
      <v-dialog
        v-model="showDocumentsDialog"
        v-if="this.documents"
        width="900"
        persistent
        scrollable
      >
      <v-card>
        <v-card-title class="headline">Documents</v-card-title>
        <v-card-text>
          <v-container>
            <v-row no-gutters style="margin-top: -20px">
              <table class="history_table">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(data, index) in this.documents"
                    :key="index"
                    class="text-center"
                >
                  <td>{{ data.original_file_name}}</td>
                  <td>
                    <a :href="s3BucketURL + data.file_path" target="_blank">
                      <v-icon > mdi-eye</v-icon>
                    </a>
                  </td>
                </tr>
                </tbody>
              </table>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=" darken-1"
              class="ma-2 white--text blue-color"
              text
              @click="
              () => {
                showDocumentsDialog = false;
                documents = null
              }
            "
          >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import constantValue from "@/utils/constants";
import CustomerModel from "../../Clients/Customer/CustomerModel";
import CustomerFormForWalkIn from "./CustomerFormForWalkIn.vue";
import ScheduleIcon from "@/assets/images/trainers/schedule-icon.svg";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
import CustomerNotes from "@/components/Customer/CustomerNotes.vue";

export default {
  props: {
    workshopLevels: {
      type: Array,
      default: () => [],
    },
    workshop: Object,
    sliderChange: Number,
    refresh: Boolean,
  },

  data() {
    return {
      walkInCustomerFormDialog: false,
      customerScheduleDialoge: false,
      customerScheduleData: [],
      userModel: { userID: null, type: "details" },
      constant: constantValue,
      customer: { dob: null },
      isLoading: false,
      customerList: [],
      names: [],
      program_names: [],
      program_name: null,
      name: null,
      phones: [],
      phone: null,
      emails: [],
      email: null,
      searchParams: {},
      totalPages: 1,
      page: 1,
      perPage:10,
      customerId: null,
      programId: null,
      historyOrderId: null,
      orderId: null,
      scheduleOrderId: null,
      showDocumentsDialog:false,
      documents:[],
      fileName: "Academy-Student-Report",
      notesDialogue: false,
      notesBookingId:null,
      notesCustomerId:null,
      notes:[],
    };
  },
  components: {
    CustomerNotes,
    CustomerModel,
    CustomerFormForWalkIn,
    ScheduleIcon,
    ExportIcon,
    SvgIcon,
  },
  computed: {},
  watch: {
    page() {
      this.getWorkshopBookingByProgramId();
    },
    refresh() {
      if (this.programId) {
        this.getWorkshopBookingByProgramId();
      }
    },
    sliderChange(val) {
      if (val == 0 || val > 0) {
        this.page = 1;
        this.programId = this.workshop.programs[val].id;
        this.getWorkshopBookingByProgramId();
      }
    },
    name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("name", val);
    },
    phone(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("mobile", val);
    },
    email(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("email", val);
    },
    program_name(val) {
      if (val == "" || val == null) return;
      if (this.isLoading) return;
      this.filterSearch("program_names", val);
    },
  },
  mounted() {
    if (this.workshop && this.workshop.programs.length != 0) {
      if (this.sliderChange) {
        this.programId = this.workshop.programs[this.sliderChange].id;
      } else {
        this.programId = this.workshop.programs[0].id;
      }
      this.getWorkshopBookingByProgramId();
    }
  },

  methods: {
    closeDialogue(){
      this.notesDialogue= false;
      this.notes=[];
    },
    refreshBookings(){
      this.getNotes(this.notesBookingId)
    },
    async getNotes(customer_id){
      this.showLoader('Loading notes')
      await this.$http.get(`venues/workshops/schedules/booking/get-notes/${customer_id}`).then((response) => {
        if (response.status === 200 && response.data.status) {
          this.notes = response.data.data;
        }else{
          this.notes = [];
        }
      }).catch((error) => {
        this.errorChecker(error);
      }).finally(()=>{
        this.hideLoader()
      });
    },
    async openNotes(customer){
      await this.getNotes(customer.id);
      this.notesBookingId = customer.id;
      this.notesCustomerId = customer.customer_id;
      this.notesDialogue = true;
    },
    getFilterData() {
      this.page = 1;
      this.getWorkshopBookingByProgramId();
    },

    customerModelActions() {
      this.getWorkshopBookingByProgramId();
    },

    filterSearch(type, val) {
      if (val == "All") return;
      val = val.replace(/[^\w \t@]+|@(?!\w)[^\w \t.]+|.(?!\w)/g, "");
      if (!val) return;
      this.isLoading = true;
      this.$http
        .get(
          `venues/workshops/schedules/customers/filters?field=${type}&search=${val}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status) {
            this.isLoading = false;
            if (type == "name") {
              this.names = response.data.data;
              this.names.unshift(val);
              this.names.unshift("All");
            } else if (type == "mobile") {
              this.phones = response.data.data;
              this.phones.unshift(val);
              this.phones.unshift("All");
            } else if (type == "email") {
              this.emails = response.data.data;
              this.emails.unshift(val);
              this.emails.unshift("All");
            } else if (type == "program_names") {
              this.program_names = response.data.data;
              this.program_names.unshift(val);
              this.program_names.unshift("All");
            }
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getOrderDetails(data) {
      if (data.parent_order_ids) {
        var orderIds = [data.order_id];
        data.parent_order_ids.split(",").forEach((id) => {
          orderIds.push(Number(id));
        });
        this.$emit("receipt", orderIds);
      } else {
        this.$emit("receipt", data.order_id);
      }
    },

    getFilterUrl() {
      let url = `&page=${this.page}&per_page=${this.perPage}`;
      if (this.searchParams.name == "All") {
        this.searchParams.name = null;
      } else if (this.searchParams.name != null) {
        url += "&name=" + this.searchParams.name;
      }

      if (this.searchParams.email == "All") {
        delete this.searchParams.email;
      } else if (this.searchParams.email != null) {
        url += "&email=" + this.searchParams.email;
      }
      if (this.searchParams.mobile == "All") {
        delete this.searchParams.mobile;
      } else if (this.searchParams.mobile != null) {
        url += "&mobile=" + this.searchParams.mobile;
      }
      if (this.searchParams.level_id == "All") {
        delete this.searchParams.level;
      } else if (this.searchParams.level_id) {
        url += "&level_id=" + this.searchParams.level_id;
      }

      if (this.searchParams.program_name == "All") {
        delete this.searchParams.program_name;
      } else if (this.searchParams.program_name) {
        url += "&program_names=" + this.searchParams.program_name;
      }

      return url;
    },

    getCustomerSchedules(customer) {
      this.customerScheduleData = [];
      this.$http
        .get("venues/workshops/schedules/bookings/" + customer.booking_id)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.customerScheduleData = response.data.data;
            this.customerScheduleDialoge = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    getWorkshopBookingByProgramId() {
      let url = this.getFilterUrl();
      this.showLoader("Loading");
      this.$http
        .get(
          `/venues/workshops/schedules/booking?program_id=${this.programId}${url}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.customerList = response.data.data;
            this.totalPages = response.data.total_pages;
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    editBooking(orderId) {
      if (this.workshop.workshop_type_id == 1) {
        this.$emit("orderId", orderId);
      } else {
        this.orderId = orderId;
        this.walkInCustomerFormDialog = true;
      }
    },

    showUserModel(userId) {
      this.userModel.userID = parseInt(userId);
      this.userModel.type = "details";
    },
    viewSessionForm(orderId) {
      this.$emit("sessionPopup", orderId);
    },
    exportData() {
      let type = "excel";
      let url = this.getFilterUrl();
      if (!url) return;
      this.showLoader("Downloading report");
      this.fileName = "Academy-Student-Report";
      this.$http
        .get(
          `venues/workshops/schedules/booking/export?program_id=${this.programId}${url}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    showDocuments(customer){
      this.documents = [];
      if(customer.document_id){
        this.documents.push(customer.document);
      }else{
        this.documents = customer.documents;
      }
      this.showDocumentsDialog = true
    }
  },
};
</script>

<style scoped>
.button_navigation {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.button_navigation .v-item--active {
  background-color: #062b48 !important;
  color: #fff !important;
}
.v-select-list >>> .v-subheader {
  font-size: 0.95rem !important;
  font-weight: 600 !important;
  color: black;
}
span.extra-classing {
  display: block;
  padding: 10px 0px;
  text-align: center;
  font-size: 14px;
}
</style>
