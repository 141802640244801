<template>
  <v-dialog v-model="customerFormDialoge" scrollable persistent width="760px">
    <v-form ref="form">
      <v-card>

        <v-card-text class="">
          <div class="row pt-1">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text=" Customer" style="color: black">
                </SvgIcon>
                <div class="d-flex gap-x-1">
                  <v-autocomplete
                      v-if="(promotions.length > 0 && prorateDetails.price) || (promotions.length > 0 && editFlag) || (promotions.length > 0 && package_type == 2)"
                      :items="[{ name: 'None', promotion_code: null }, ...promotions]"
                      item-text="name"
                      height="50"
                      item-value="promotion_code"
                      v-model="registerForm.promotion_code"
                      background-color="rgb(206, 168, 0)"
                      outlined
                      :readonly="discountApplied && editFlag"
                      @change="verifyBenefit('promotion')"
                      placeholder="Promotions"
                      dense
                      hide-details="auto"
                      class="q-autocomplete shadow-0"
                  >
                  </v-autocomplete>
                  <v-btn fab x-small class="shadow-0" @click="close">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <!--          <v-row>-->
          <!--            <v-col md="12">-->
          <!--              <div class="font-semibold text-base text-blue">Program details-->
          <!--                <span class="ml-2" style="display: inline-block;">-->
          <!--                  <v-radio-group-->
          <!--                    style="height:40px; margin:0 auto;"-->
          <!--                    v-model="package_type"-->
          <!--                    @change="packageChange"-->
          <!--                    row-->
          <!--                  >-->
          <!--                    <v-radio-->
          <!--                        label="Periodic Slots"-->
          <!--                        :value="1"-->
          <!--                        class="custom-radio"-->
          <!--                        color="green"-->
          <!--                        :readonly="editFlag"-->
          <!--                    />-->
          <!--                    <v-radio-->
          <!--                        label="Session Based"-->
          <!--                        :value="2"-->
          <!--                        class="custom-radio"-->
          <!--                        color="red"-->
          <!--                        :readonly="editFlag"-->
          <!--                    />-->
          <!--                    <v-radio-->
          <!--                      v-if="checkReadPermission($modules.workshops.programBased.slug)"-->
          <!--                      label="Program Based"-->
          <!--                      :value="3"-->
          <!--                      class="custom-radio"-->
          <!--                      color="red"-->
          <!--                      @click.stop-->
          <!--                      :readonly="editFlag"-->
          <!--                  />-->
          <!--                  </v-radio-group>-->
          <!--                  </span>-->
          <!--              </div>-->
          <!--            </v-col>-->
          <!--          </v-row>-->

          <v-divider class="mt-2 "/>
          <v-row class="mt-2" dense>
            <v-col cols="6">
              <label>Program*</label>
              <v-select
                  :items="workshopPrograms"
                  item-text="name"
                  item-value="id"
                  v-model="programSelected"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  :readonly="editFlag"
                  background-color="#fff"
                  @change="programChange(), checkProrate()"
                  :rules="[(v) => !!v || 'Program is required']"
                  required
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  validate-on-blur
              >
              </v-select>
            </v-col>

            <v-col sm="6">
              <label>Product*</label>

              <v-select
                  v-model="workshopProduct"
                  :rules="[(v) => !!v.product_id || 'Product is required']"
                  :items="productAndSchedules.pricing"
                  item-text="name"
                  item-value="product_id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  required
                  :readonly="editFlag"
                  return-object
                  background-color="#fff"
                  @change="selectPackageType(),programChange(), checkProrate()"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  validate-on-blur
              ></v-select>


              <!--              <v-select-->
              <!--                  label="Product*"-->
              <!--                  :items="productAndSchedules.pricing"-->
              <!--                  return-object-->
              <!--                  item-text="name"-->
              <!--                  item-value="product_id"-->
              <!--                  v-model="workshopProduct"-->
              <!--                  @change="programChange(), checkProrate()"-->
              <!--                  outlined-->
              <!--                  :rules="[(v) => !!v || 'Package is required']"-->
              <!--                  :menu-props="{ bottom: true, offsetY: true }"-->
              <!--                  background-color="#fff"-->
              <!--                  :disabled="editFlag"-->
              <!--              >-->
              <!--                <template slot="item" slot-scope="data">-->
              <!--                  <template v-if="!(typeof data.item == 'object')">-->
              <!--                    <v-list-item-content style="text-align: center">-->
              <!--                      {{ data.item }}-->
              <!--                    </v-list-item-content>-->
              <!--                  </template>-->
              <!--                  <template v-else>-->
              <!--                    <v-list-item-content>-->
              <!--                      <v-list-item-title>-->
              <!--                        {{ data.item.name }}-->
              <!--                      </v-list-item-title>-->
              <!--                      <v-list-item-subtitle>-->
              <!--                        {{ data.item.type }}-->
              <!--                      </v-list-item-subtitle>-->
              <!--                    </v-list-item-content>-->
              <!--                  </template>-->
              <!--                </template>-->
              <!--              </v-select>-->


            </v-col>

            <v-col cols="6">
              <label>Price</label>

              <v-text-field
                  readonly
                  v-model="workshopProduct.price"
                  outlined
                  :disabled="!workshopProduct.price"
                  background-color="#fff"
                  append="Discount"
                  :prefix="currencyCode"
                  class="q-text-field shadow-0"
                  hide-details="auto"
                  dense
                  validate-on-blur
              >
                <template v-slot:append v-if="workshopProduct.discount">
                  <div class="text-decoration-line-through">
                    {{ currencyCode }}
                    {{ workshopProduct.discount.actual_price }}
                  </div>
                </template>
              </v-text-field>
            </v-col>
            <v-col sm="6">
              <label>Level</label>
              <v-select
                  v-model="level_id"
                  :items="levels"
                  item-text="name"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  validate-on-blur
              ></v-select>
            </v-col>
            <v-col md="6" class="pb-0 pt-0">
              <label>Select class start date*</label>
              <date-field
                  :backFill="checkBackfillPermission($modules.workshops.schedule.slug)"
                  v-model="startDate"
                  label=""
                  :readonly="package_type === 3"
                  :rules="[(v) => !!v || 'Start date is required']"
                  @change="programChange(), checkProrate()"
                  :minDate="workshop.start_date"
                  :maxDate="workshop.end_date"
                  class-name="q-text-field shadow-0"
                  :hide-details="true"
                  :dense="true"
              >
              </date-field>
            </v-col>
            <v-col
                sm="6"
                v-if="checkReadPermission($modules.salesTeam.management.slug)"
                class="pb-0 pt-0"
            >
              <label>Sales Team</label>
              <v-select
                  v-model="registerForm.sales_team_id"
                  :items="salesTeams"
                  item-text="name"
                  item-value="id"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  class="q-autocomplete shadow-0"
                  hide-details="auto"
                  dense
                  validate-on-blur
              ></v-select>
            </v-col>
            <template v-if="package_type == 1 && workshop.workshop_type_id == 1">
              <v-col
                  sm="12"
                  class="pb-0 pt-0"
                  v-for="(dateRange, drIndex) in productAndSchedules.date_ranges"
                  :key="drIndex"
              >
                <div>
                  <div class="titles mt-5">
                    Date Range: {{ dateRange.start_date | dateformat }} - {{ dateRange.end_date | dateformat }}
                  </div>
                  <v-card
                      outlined
                      :style="cardStyle"
                      class="mt-2"
                      v-for="(days, index) in dateRange.selectedDays"
                      :key="index"
                      :disabled="dateRange.end_date < startDate ? true : false"
                  >
                    <v-card-text>
                      <v-row sm="12">
                        <v-col sm="6">
                          <label>Day*</label>

                          <v-select
                              :disabled="
                              dateRange.end_date < startDate ? true : false
                            "
                              v-model="days.day"
                              :items="dateRange.weekdays"
                              item-text="name"
                              item-value="id"
                              outlined
                              :menu-props="{ bottom: true, offsetY: true }"
                              return-object
                              @change="daysChange(drIndex, index)"
                              background-color="#fff"
                              class="q-autocomplete shadow-0"
                              hide-details="auto"
                              dense
                              validate-on-blur
                          ></v-select>
                        </v-col>

                        <v-col>
                          <label>Select Time slot</label>

                          <v-select
                              :disabled="
                              dateRange.end_date < startDate ? true : false
                            "
                              v-model="days.workshop_schedule_id"
                              :items="days.day.time"
                              item-text="name"
                              item-value="workshop_schedule_id"
                              outlined
                              :menu-props="{ bottom: true, offsetY: true }"
                              background-color="#fff"
                              @change="checkProrate"
                              class="q-autocomplete shadow-0"
                              hide-details="auto"
                              dense
                              validate-on-blur
                              :rules="
                              dateRange.end_date < startDate
                                ? []
                                : [(v) => !!v || 'Time Slot is required']
                            "
                          >
                            <template
                                slot="item"
                                slot-scope="{ item, on, attrs }"
                            >
                              <v-list-item
                                  ripple
                                  v-bind="attrs"
                                  v-on="on"
                                  :disabled="
                                  checkStartDatePassFromEndDateOfClass(item)
                                "
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ item.name }}
                                  </v-list-item-title
                                  >
                                  <v-list-item-subtitle>
                                    <div
                                        class="text-trancate"
                                        style="max-width: 100px"
                                    >
                                      {{ item.facility_name || "NA" }}
                                    </div>
                                    <div
                                        class="text-trancate mt-1"
                                        style="max-width: 100px"
                                    >
                                      {{ item.start_date | dateformat }} -to-
                                      {{ item.end_date | dateformat }}
                                    </div>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                          v-bind="attrs"
                                          v-on="on"
                                          fab
                                          x-small
                                          icon
                                      >
                                        <v-icon>mdi-information</v-icon>
                                      </v-btn>
                                    </template>
                                    Trainers :-
                                    {{ item.trainer || "NA" }}
                                  </v-tooltip>
                                </v-list-item-action>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                  <div
                      class="add_btn"
                      v-if="checkAddButtonStatus(drIndex) &&  startDate <= dateRange.end_date"
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            color="blue-color"
                            fab
                            x-small
                            dark
                            @click="addDay(drIndex)"
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      Add Day
                    </v-tooltip>
                  </div>
                </div>
              </v-col>
            </template>
          </v-row>

          <v-row v-if="prorateDetails.dates && prorateDetails.dates.length && package_type == 1" dense>
            <v-alert
                type="info"
                outlined
                text
                style="margin: 5px auto !important"
            >
              Customer will be able to attend
              {{ prorateDetails.available_slots }}/{{
                prorateDetails.total_slots
              }}
              classes which will start from
              {{ prorateDetails.dates[0].selected_date | dateformat }} and end
              by
              {{
                prorateDetails.dates[prorateDetails.dates.length - 1]
                    .selected_date | dateformat
              }}. <br/>
              <b>Fee: {{ prorateDetails.price | toCurrency }}</b>
              <span
                  class="text-decoration-line-through"
                  v-if="prorateDetails.product.price > prorateDetails.price"
              >
                {{ prorateDetails.product.price | toCurrency }}
              </span>
              <div class="mt-2">
                <b>Occurrences: </b>
                <span
                    v-for="(date, index) in prorateDetails.dates"
                    :key="index"
                >
                  <span v-if="index == 0">{{
                      date.selected_date | dateformat
                    }}</span>
                  <span v-else-if="index == prorateDetails.dates.length - 1"
                  >and {{ date.selected_date | dateformat }}</span
                  >
                  <span v-else>, {{ date.selected_date | dateformat }}</span>
                </span>
              </div>
            </v-alert>
          </v-row>


          <v-row class="mt-2">
            <div class="col-md-12">
              <div class="d-flex align-center justify-space-between">
                <div class="font-semibold text-base text-blue">Customer details</div>
              </div>
            </div>
          </v-row>

          <v-card class="bordered shadow-0 mt-2">
            <v-card-text>
              <v-row dense class="mb-4" v-if="!orderId">
                <v-col md="12">
                  <div class="d-flex justify-space-between">
                    <div class="d-flex">
                      <card-reader-button
                          className=" mt-5 text-blue text-capitalize light-blue-color"

                          label="Samsotech Reader"
                          docType="chip"

                          @data="
                          (data) => {
                            setCardData(data);
                          }
                        "
                      ></card-reader-button>
                      <card-data-button
                          className="mt-5 ml-4 text-blue text-capitalize light-blue-color"
                          label="HID Omnikey"
                          @data="
                          (data) => {
                            setCardData(data);
                          }
                        "
                      ></card-data-button>
                    </div>


                  </div>
                </v-col>


              </v-row>

              <v-row dense align="center">
                <v-col md="3">
                  <div style="aspect-ratio: 1/1 ">
                    <image-uploader
                        @upload="
                          (data) => {
                             registerForm.image = data;
                          }
                        "
                        @remove="
                          () => {
                            registerForm.image = null;
                          }
                        "
                        :image_path="registerForm.image_path"
                        :height="240"
                        defaultImage="user"
                        :show-guide="false"
                        message-text=""
                        text=""
                    ></image-uploader>
                  </div>
                </v-col>
                <v-col md="9">
                  <v-row dense>
                    <v-col md="12">
                      <label>Mobile Number*</label>
                      <v-mobile-search
                          v-model="registerForm.search"
                          :selected="registerForm.mobile"
                          @updateCustomer="setCustomerData($event)"
                          ref="mobile"
                          hide-details="auto"
                          class-name1="q-text-field shadow-0"
                          class-name2="q-text-field shadow-0 mobile_auto_complete_hide_anchor"
                          background-color=""
                          :dense="true"
                          label=""
                          :show-label="false"
                          :dial-code-grid-size="3"
                      ></v-mobile-search>
                    </v-col>

                    <v-col md="12">
                      <label>Customer Name*</label>
                      <v-name-search
                          :selected="registerForm.name"
                          :mobile="registerForm.mobile"
                          :email="registerForm.email"
                          v-model="registerForm.nameSearch"
                          @updateCustomer="setCustomerData($event)"
                          class-name="q-text-field shadow-0"
                          :dense="true"
                          hide-details="auto"
                          label=""
                      ></v-name-search>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row dense>


                <v-col cols="12" sm="6" md="6">
                  <label>Email*</label>

                  <v-text-field
                      v-model="registerForm.email"
                      outlined
                      background-color="#fff"
                      required
                      :rules="[
                      (v) => !!v || 'E-mail is required',
                      (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
                    ]"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                      validate-on-blur
                  ></v-text-field>
                </v-col>
                <v-col md="6" v-if="customerAgeRange">
                  <label>Age Group</label>
                  <v-select
                      v-if="customerAgeRange"
                      v-model="registerForm.age_group"
                      :items="ageRanges"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ bottom: true, offsetY: true }"
                      outlined
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      validate-on-blur
                  ></v-select>
                </v-col>
                <v-col md="6" v-else>
                  <label>Date Of Birth</label>
                  <date-of-birth-field v-model="registerForm.dob" :dense="true"></date-of-birth-field>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>Nationality</label>

                  <v-autocomplete
                      v-model="registerForm.country_id"
                      :items="countries"
                      item-value="id"
                      item-text="name"
                      outlined
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      validate-on-blur
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="6">
                  <label>Gender</label>

                  <v-select
                      v-model="registerForm.gender"
                      :items="['Male', 'Female']"
                      outlined
                      :menu-props="{ bottom: true, offsetY: true }"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      validate-on-blur
                  ></v-select>
                </v-col>
                <v-col sm="12" md="6">
                  <label>Tags</label>
                  <v-select
                      :items="tags"
                      :placeholder="`Tags`"
                      outlined
                      item-value="id"
                      item-text="name"
                      :menu-props="{ bottom: true, offsetY: true }"
                      v-model="registerForm.customer_tag"
                      background-color="#fff"
                      class="q-autocomplete shadow-0"
                      dense
                      hide-details="auto"
                      validate-on-blur
                  ></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <label>Document</label>
                  <v-file-input
                      :label="registerForm.document.id != null ? '' : 'Document'"
                      v-model="registerForm.document.file"
                      prepend-inner-icon="mdi-paperclip"
                      prepend-icon
                      outlined
                      background-color="#fff"
                      class="q-text-field shadow-0"
                      dense
                      hide-details="auto"
                  >
                    <template v-slot:label>
                      <span v-if="!registerForm.document.id">
                        Select Document file
                      </span>
                      <span
                          v-if="
                          registerForm.document.id &&
                            !registerForm.document.file
                        "
                          class="font-weight-bold"
                      >
                        <span
                            style="width: 70%; display: inline-block"
                            class="text-truncate"
                        >{{ registerForm.document.original_file_name }}</span
                        >
                        <span
                            style="width: 20%; display: inline-block"
                            class="text-truncate"
                        >.{{
                            registerForm.document.original_file_name.split(".")[
                            registerForm.document.original_file_name.split(
                                "."
                            ).length - 1
                                ]
                          }}</span
                        >
                      </span>
                    </template>
                    <template v-slot:prepend-inner>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                              color="cyan"
                              v-if="
                              registerForm.document.id &&
                                !registerForm.document.file
                            "
                              @click="openFile(registerForm.document.file_path)"
                              v-on="on"
                          >
                            mdi-download-box
                          </v-icon>
                          <v-icon v-else v-on="on">mdi-image</v-icon>
                        </template>
                        <span v-if="registerForm.document.file_path">
                          Download image</span
                        >
                        <span v-else>Upload Image</span>
                      </v-tooltip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col md="12">
                  <div class="d-flex gap-x-5">
                    <span class="d-flex align-center"><v-checkbox :ripple="false" v-model="registerForm.opt_marketing"/> Opt In Marketing</span>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>


          <v-row class="mt-2 " v-if="workshop.documents.length > 0">
            <div class="col-md-12">
              <div class="d-flex align-center justify-space-between">
                <div class="font-semibold text-base text-blue">Documents</div>
              </div>
            </div>
          </v-row>


          <v-card
              class="shadow-0 bordered mt-2"
              v-if="workshop.documents.length > 0"
          >
            <v-card-text>
              <v-row>
                <v-col
                    md="2"
                    sm="2"
                    v-for="document in workshop.documents"
                    :key="document.id"
                >
                  <div
                      align="center"
                      @click="openFile(document.file_path)"
                      style="cursor: pointer"
                  >
                    <v-btn large text>
                      <v-icon large>mdi-file-document</v-icon>
                    </v-btn>
                    <div class="title">{{ document.name }}</div>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-row class="mt-2 " v-if="documentFields.length > 0">
            <div class="col-md-12">
              <div class="d-flex align-center justify-space-between">
                <div class="font-semibold text-base text-blue">Documents</div>
              </div>
            </div>
          </v-row>


          <v-card
              class="shadow-0 bordered mt-2"
              elevation="0"
              v-if="documentFields.length"
          >
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="12"
                  v-for="(documentField, index) in documentFields"
                  :key="index"
              >
                <v-file-input
                    :label="documentField.name"
                    v-model="documentField.file"
                    prepend-inner-icon="mdi-paperclip"
                    prepend-icon
                    outlined
                    background-color="#fff"
                    :rules="documentUploadRule(documentField)"
                    dense
                    hide-details="auto"
                    class="q-text-field shadow-0"
                >
                  <template v-slot:prepend-inner>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                            color="cyan"
                            v-if="
                documentField.document_id &&
                  documentField.file_path
              "
                            @click="openFile(documentField.file_path)"
                            v-on="on"
                        >
                          mdi-download-box
                        </v-icon>
                        <v-icon v-else v-on="on"> mdi-paperclip</v-icon>
                      </template>
                      <span
                          v-if="
              documentField.document_id &&
                documentField.file_path
            "
                      >Download uploaded file</span
                      >
                      <span v-else>Upload Document</span>
                    </v-tooltip>
                  </template>

                  <template v-slot:label>
                  <span v-if="!documentField.isEdit">
                    {{ documentField.name }}
                  </span>
                    <span
                        v-if="
            documentField.isEdit == true &&
              documentField.file == null &&
              documentField.original_file_name
          "
                        class="font-weight-bold"
                    >
          <span
              style="width: 70%; display: inline-block"
              class="text-truncate"
          >{{ documentField.original_file_name }}</span
          >
          <span
              style="width: 20%; display: inline-block"
              class="text-truncate"
          >.{{
              documentField.original_file_name.split(".")[
              documentField.original_file_name.split(".")
                  .length - 1
                  ]
            }}</span
          >
        </span>
                  </template>
                  <template
                      v-slot:append
                      v-if="documentField.uploaded_file_path"
                  >
                    <v-btn
                        @click="openFile(documentField.uploaded_file_path)"
                        class="text-truncate"
                        depressed
                        color="primary"
                        text
                        style="transform: translate(0, -22%)"
                    >
                      Download {{ documentField.name }}
                    </v-btn>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
          </v-card>

        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              class="ma-2  "
              text
              @click="close"
          >Close
          </v-btn
          >
          <v-btn @click="save"
                 color=" darken-1"

                 class="ma-2 white--text blue-color">{{
              editFlag ? "Update" : "Save"
            }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ImageUploader from "@/components/Image/ImageUploader.vue";
import DateOfBirthField from "@/components/Fields/DateOfBirthField.vue";
import {pack} from "core-js/internals/ieee754";

export default {
  components: {DateOfBirthField, ImageUploader, SvgIcon},
  props: {
    customerFormDialoge: {
      type: Boolean,
      default: false,
    },
    "order-id": {type: Number, default: null},
    workshop: Object,
  },
  data() {
    return {
      workshopPrograms: [],
      productAndSchedules: {
        products: [],
      },
      workshopBookingId: null,
      registerForm: {document: {id: null}, opt_marketing: false},
      editFlag: false,
      programSelected: {},
      workshopProduct: {
        price: null,
      },
      selectedDays: [{day: {time: []}}],
      levels: [],
      schedules: [],
      weekdays: [],
      level_id: null,
      prorateDetails: {},
      discountApplied: false,
      startDate: moment().format("YYYY-MM-DD"),
      documentFields: [],
      package_type: 1,
    };
  },
  watch: {
    customerFormDialoge: {
      immediate: true,
      handler(val) {
        if (val) {
          this.reset();
          this.prorateDetails = {};
          if (this.orderId) {
            this.getOrderDetails();
          }
          let date = moment(this.workshop.start_date, "YYYY-MM-DD").isBefore(
              moment()
          )
              ? moment().format("YYYY-MM-DD")
              : this.workshop.start_date;
          this.$store.dispatch("loadPromotions", {
            date: date,
            venue_service_id: this.workshop.venue_service_id,
            product_type: "Academy",
          });
          this.workshopProduct = {price: null};
          this.getLevels();
          this.getConfiguration()
        }
      },
    },
    workshop(val) {
      if (val) {
        this.workshopPrograms = val.programs;
      }
    },
  },
  mounted() {
    if (this.$store.getters.getDocumentTypes.status == false) {
      this.$store.dispatch("loadDocumentTypes");
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
    if (this.$store.getters.getTags.status == false) {
      this.$store.dispatch("loadTags");
    }
    this.checkPermission = this.checkExportPermission(
        this.$modules.salesTeam.dashboard.slug
    );
    if (this.checkPermission) {
      this.$store.dispatch("loadSalesTeams", "Academy");
      this.$forceUpdate();
    }
    if (this.$store.getters.getCustomerAgeRangeConfiguration.status == false) {
      this.$store.dispatch("LoadCustomerAgeRangeConfiguration");
    }
    if (this.$store.getters.getCustomerAgeRange.status == false) {
      this.$store.dispatch("LoadCustomerAgeRange");
    }
  },
  computed: {
    customerAgeRange() {
      return this.$store.getters.getCustomerAgeRangeConfiguration.data;
    },
    ageRanges() {
      return this.$store.getters.getCustomerAgeRange.data;
    },
    tags() {
      return this.$store.getters.getTags.data;
    },
    documentTypes() {
      return this.$store.getters.getDocumentTypes.data;
    },
    countries() {
      return this.$store.getters.getCountries.data;
    },
    promotions() {
      return this.$store.getters.getPromotions.data;
    },
    getWeekDays() {
      return JSON.parse(JSON.stringify(this.$store.getters.getWeekdays.data));
    },
    salesTeams() {
      return this.$store.getters.getSalesTeams.data;
    },
  },
  methods: {
    pack,
    close() {
      this.documentFields = [];
      this.$emit("close");
    },

    reset() {
      this.editFlag = false;
      this.registerForm = {document: {id: null}, opt_marketing: false};
      this.workshopProduct = {
        price: null,
      };
      this.level_id = null;
      this.productAndSchedules = {
        products: [],
      };
      this.weekdays = [];
      this.selectedDays = [{day: {time: []}}];
      this.programSelected = {};
      setTimeout(() => {
        // this.$refs["image_upload"].cancel();
        this.$refs.form.resetValidation();
      });
    },
    checkAddButtonStatus(drIndex = null) {
      if (this.productAndSchedules.date_ranges[drIndex]) {
        if (this.productAndSchedules.date_ranges[drIndex].selectedDays) {
          return (
              this.productAndSchedules.date_ranges[drIndex].selectedDays.length <
              this.workshopProduct.frequency
          );
        } else {
          return false;
        }
      }
    },
    addDay(drIndex = null) {
      if (this.checkAddButtonStatus(drIndex)) {
        this.productAndSchedules.date_ranges[drIndex].selectedDays.push({
          day: {time: []},
        });
        this.$forceUpdate();
      }
    },
    async getOrderDetails() {
      this.showLoader("Loading");
      await this.$http
          .get(`venues/workshops/schedules/booking/${this.orderId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.hideLoader();
              this.editFlag = true;
              const data = response.data.data;
              this.workshopBookingId = data.workshop_order.id;
              this.package_type = data.workshop_order.package_type;
              this.startDate = data.workshop_order.start_date;
              this.programSelected = data.workshop_order.workshop_program_id;
              this.programChange();

              let productExist = this.productAndSchedules.pricing.find(
                  (x) => x.product_id == data.product_id
              );
              if (productExist) {
                this.workshopProduct = productExist;
              } else {
                this.workshopProduct = {price: null};
              }
              this.level_id = data.workshop_order.level_id;
              this.productAndSchedules.date_ranges.forEach((dRange, drIndex) => {
                this.productAndSchedules.date_ranges[drIndex].selectedDays = [];
              });
              data.workshop_order.booking.forEach((booking) => {
                const dateRangeIndex = this.productAndSchedules.date_ranges.findIndex(
                    (dRange) => {
                      return dRange.id === booking.date_range_id;
                    }
                );
                if (dateRangeIndex !== -1) {
                  this.productAndSchedules.date_ranges[
                      dateRangeIndex
                      ].weekdays.map((weekday) => {
                    if (weekday.bit_value === booking.weekdays[0]) {
                      this.productAndSchedules.date_ranges[
                          dateRangeIndex
                          ].selectedDays.push({
                        day: weekday,
                        workshop_schedule_id: booking.workshop_schedule_id,
                        workshop_booking_id: booking.workshop_booking_id,
                        id: booking.id,
                      });
                    }
                  });
                }
              });
              if (data.workshop_order.document_id) {
                this.$set(
                    this.registerForm,
                    "document",
                    data.workshop_order.document
                );
              }
              let customer = data.workshop_order.customer;
              customer.customer_id = customer.id;
              customer.image_path = customer.profile_image;

              this.discountApplied = false;
              if (data.discount != null) {
                this.workshopProduct.price = data.price;
                this.workshopProduct.discount = data.discount;
                this.discountApplied = true;
                if (data.discount.promotion != null) {
                  this.registerForm.promotion_code =
                      data.discount.promotion.promotion_code;
                }
              }
              this.setCustomerData(customer);
              this.checkProrate();
              // this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    getTitle(index) {
      let arr = [
        {index: 1, name: 'Periodic Slots'},
        {index: 2, name: 'Session Based'},
        {index: 3, name: 'Program Based'}
      ];
      return this.findNameByIndex(arr, index);
    },
    findNameByIndex(arr, index) {
      const item = arr.find(item => item.index === index);
      return item ? item.name : 'Generic';
    },
    selectPackageType() {
      this.package_type = this.workshopProduct.package_type;
    },
    programChange() {
      if (this.startDate < this.workshop.start_date) {
        this.showError("Workshop start date is " + this.workshop.start_date);
        return;
      } else if (this.startDate > this.workshop.end_date) {
        this.showError("Workshop end date is " + this.workshop.end_date);
        return;
      }
      this.productAndSchedules = {
        products: [],

        date_ranges: [],
      };
      let program = this.workshop.programs.find(
          (x) => x.id == this.programSelected
      );
      this.productAndSchedules.date_ranges = program.date_ranges;

      let pricing = [];

      let groupedPackages = program.pricing.reduce((acc, pkg) => {
        // Find if the package_type_id already exists in the accumulator
        let group = acc.find(g => g.package_type === pkg.package_type);
        if (group) {
          // If it exists, add the package to the items array
          group.items.push(pkg);
        } else {
          // If it doesn't exist, create a new group and add the package to the items array
          acc.push({
            package_type: pkg.package_type,
            items: [pkg]
          });
        }
        return acc;
      }, []);


      groupedPackages.forEach((item) => {

        let title = this.getTitle(item.package_type);

        pricing.push({header: title});
        pricing.push({divider: true});

        item.items.forEach((pkg) => {
          pricing.push(pkg);
        });
      });


      // this.productAndSchedules.pricing = program.pricing.filter((prod) => prod.package_type == this.package_type);
      this.productAndSchedules.pricing = pricing;

      this.productAndSchedules.date_ranges.forEach((dateRange, drIndex) => {
        let weekDays = [];
        if (this.productAndSchedules.date_ranges[drIndex].weekdays) {
          this.productAndSchedules.date_ranges[drIndex].weekdays = null;
        }
        dateRange.schedules.forEach((schedule) => {
          schedule.weekdays.forEach((element) => {
            let findValue = this.getWeekDays.find(
                (y) => y.bit_value === element
            );
            let obj = {
              trainer: schedule.trainer
                  .map((trainer) => trainer.first_name + trainer.last_name)
                  .join(","),
              facility_name: schedule.facility_name
                  ? schedule.facility_name
                  : schedule.location,
              workshop_schedule_id: schedule.id,
              start_time: schedule.start_time,
              end_time: schedule.end_time,
              start_date: dateRange.start_date,
              end_date: dateRange.end_date,
              name:
                  moment(schedule.start_time, "HH:mm").format("h:mm a") +
                  " To " +
                  moment(schedule.end_time, "HH:mm").format("h:mm a"),
            };
            let weekDaysIndex = weekDays.findIndex(
                (x) => x.bit_value == element
            );
            if (weekDaysIndex !== -1) {
              weekDays[weekDaysIndex].time.push(obj);
            } else {
              findValue.time = [];
              findValue.time.push(obj);
              weekDays.push(findValue);
            }
          });
        });
        if (this.productAndSchedules.date_ranges[drIndex].weekdays) {
          this.productAndSchedules.date_ranges[drIndex].weekdays.push(
              JSON.parse(JSON.stringify(weekDays))
          );
        } else {
          this.productAndSchedules.date_ranges[drIndex].weekdays = {};
          this.productAndSchedules.date_ranges[drIndex].weekdays = JSON.parse(
              JSON.stringify(weekDays)
          );
        }
        // if (!this.orderId) {
        this.productAndSchedules.date_ranges[drIndex].selectedDays = [
          {day: {time: []}},
        ];
        // }
      });
    },
    getLevels() {
      this.$http
          .get(
              `venues/general/color-codes/academy?venue_service_id=${this.workshop.venue_service_id}`
          )
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.levels = response.data.data;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    setCustomerData(data) {
      if (!data.customer_id) {
        this.$set(this.registerForm, "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.registerForm, "name", data.first_name);
      }

      if (
          this.registerForm.customer_id &&
          !data.customer_id &&
          this.registerForm.name != data.name &&
          this.registerForm.mobile != data.mobile
      ) {
        this.$set(this.registerForm, "mobile", null);
        this.registerForm.search = null;
        this.registerForm.nameSearch = null;
        this.$set(this.registerForm, "email", null);
        this.$set(this.registerForm, "gender", null);
        this.$set(this.registerForm, "name", null);
        this.$set(this.registerForm, "customer_id", null);
        this.$set(this.registerForm, "first_name", null);
        this.$set(this.registerForm, "image_path", null);
        this.$set(this.registerForm, "dob", null);
        this.$set(this.registerForm, "age_group", null);
        this.$set(this.registerForm, "country_id", null);
        this.$set(this.registerForm, "last_name", null);
        this.$set(this.registerForm, "customer_tag", null);
        this.$set(this.registerForm, "opt_marketing", false);
        this.$forceUpdate();
      }

      if (data.mobile) this.$set(this.registerForm, "mobile", data.mobile);
      if (data.email) this.$set(this.registerForm, "email", data.email);
      if (data.country_id) {
        this.$set(this.registerForm, "country_id", data.country_id);
      } else {
        this.$set(this.registerForm, "country_id", null);
      }
      if (data.gender) {
        this.$set(this.registerForm, "gender", data.gender);
      } else {
        this.$set(this.registerForm, "gender", null);
      }
      if (data.dob) {
        this.$set(this.registerForm, "dob", data.dob);
      } else {
        this.$set(this.registerForm, "dob", null);
      }
      if (data.age_group) {
        this.$set(this.registerForm, "age_group", data.age_group);
      } else {
        this.$set(this.registerForm, "age_group", null);
      }
      if (data.customer_tag) {
        this.$set(this.registerForm, "customer_tag", data.customer_tag);
      } else {
        this.$set(this.registerForm, "customer_tag", null);
      }
      if (data.name) this.$set(this.registerForm, "name", data.name);
      if (data.last_name) {
        this.$set(this.registerForm, "last_name", data.last_name);
      } else {
        this.$set(this.registerForm, "last_name", null);
      }
      if (data.first_name)
        this.$set(this.registerForm, "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.registerForm, "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.registerForm, "image_path", data.image_path);
      } else {
        this.$set(this.registerForm, "image_path", null);
      }
      if (data.opt_marketing) {
        if (data.opt_marketing == 1) {
          this.$set(this.registerForm, "opt_marketing", true);
        } else {
          this.$set(this.registerForm, "opt_marketing", false);
        }
      }
      this.$forceUpdate();
    },
    save() {
      if (this.prorateDetails.available_slots == 0) {
        this.showError(
            "No class available for this day. Please choose another day! "
        );
        return;
      }
      let checkAllSlotFill = false;
      if (this.package_type == 1 && this.workshop.workshop_type_id == 1) {
        this.productAndSchedules.date_ranges.forEach((dRange, drIndex) => {
          if (dRange.selectedDays && this.startDate <= dRange.end_date) {
            let last = dRange.selectedDays[dRange.selectedDays.length - 1];
            if (
                this.checkAddButtonStatus(drIndex) ||
                !last.day ||
                !last.day.bit_value ||
                !last.workshop_schedule_id
            ) {
              checkAllSlotFill = false;
              this.showError("Please select all schedules");
              return;
            } else {
              checkAllSlotFill = true;
            }
          }
        });
      } else {
        checkAllSlotFill = true;
      }
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      if (checkAllSlotFill) {
        let formData = new FormData();
        formData.append("workshop_id", this.workshop.id);
        formData.append("workshop_type_id", this.workshop.workshop_type_id);
        formData.append("workshop_program_id", this.programSelected);
        formData.append("product_id", this.workshopProduct.product_id);
        formData.append("package_type", this.package_type);
        if (this.level_id) {
          formData.append("level_id", this.level_id);
        }
        formData.append("venue_service_id", this.workshop.venue_service_id);
        if (this.editFlag) {
          formData.append("order_id", this.orderId);
          formData.append("workshop_booking_id", this.workshopBookingId);
        }

        if (this.package_type == 1 && this.workshop.workshop_type_id == 1) {
          this.productAndSchedules.date_ranges.forEach((dRange, dIndex) => {
            if (dRange.selectedDays && this.startDate <= dRange.end_date) {
              formData.append(`date_ranges[${dIndex}][date_range_id]`, dRange.id);
              formData.append(
                  `date_ranges[${dIndex}][start_date]`,
                  dRange.start_date
              );
              formData.append(
                  `date_ranges[${dIndex}][end_date]`,
                  dRange.end_date
              );
              dRange.selectedDays.forEach((el, index) => {
                formData.append(
                    `date_ranges[${dIndex}][days][${index}][bit_value]`,
                    el.day.bit_value
                );
                formData.append(
                    `date_ranges[${dIndex}][days][${index}][workshop_schedule_id]`,
                    el.workshop_schedule_id
                );
                formData.append(
                    `date_ranges[${dIndex}][days][${index}][start_date]`,
                    el.day.time.find(
                        (x) => x.workshop_schedule_id == el.workshop_schedule_id
                    ).start_date
                );

                formData.append(
                    `date_ranges[${dIndex}][days][${index}][end_date]`,
                    el.day.time.find(
                        (x) => x.workshop_schedule_id == el.workshop_schedule_id
                    ).end_date
                );

                if (this.editFlag && el.id) {
                  formData.append(
                      `date_ranges[${dIndex}][days][${index}][id]`,
                      el.id
                  );
                }
              });
            }
          });
        }

        for (let key in this.registerForm) {
          if (
              this.registerForm[key] &&
              typeof this.registerForm[key] != "object"
          ) {
            formData.append(key, this.registerForm[key]);
          } else if (key == "document" && this.registerForm[key].file) {
            formData.append(key, this.registerForm[key].file);
            if (this.registerForm[key].id) {
              formData.append("document_id", this.registerForm[key].id);
            }
          }
        }
        formData.append("opt_marketing", this.registerForm.opt_marketing);
        formData.append("start_date", this.startDate);
        let document = [];
        this.documentFields.forEach((documentField) => {
          if (documentField.file || documentField.isEdit) {
            document.push(documentField);
          }
        });

        document.forEach((element, index) => {
          formData.append(`documents[${index}][name]`, element.name);
          formData.append(`documents[${index}][id]`, element.id);
          if (element.isEdit) {
            formData.append(`documents[${index}][file_path]`, element.file_path);
            formData.append(
                `documents[${index}][document_id]`,
                element.document_id
            );
            formData.append(
                `documents[${index}][original_file_name]`,
                element.original_file_name
            );
          }

          if (element.file) {
            formData.append(`documents[${index}][file]`, element.file);
          }
        });
        if (this.registerForm.sales_team_id) {
          formData.append("sales_team_id", this.registerForm.sales_team_id);
        }
        this.showLoader("Saving..");
        this.$http
            .post(`venues/workshops/schedules/booking`, formData, {
              headers: {
                "Content-Type": "multipart/form-data; boundary=${form._boundary}",
              },
            })
            .then((response) => {
              if (response.status == 200 && response.data.status) {
                const data = response.data.data;
                this.hideLoader();
                this.$emit("complete", {order_id: data.order_id, workshop_program_id: this.programSelected});
                this.close();
                if (this.editFlag) this.showSuccess("Update successfully");
                else this.showSuccess("Created schedule successfully");
              }
            })
            .catch((error) => {
              this.errorChecker(error);
            });
      }
    },
    checkProrate() {
      // if (this.startDate < this.workshop.start_date) {
      //   // this.showError("Workshop start date is " + this.workshop.start_date);
      //   return;
      // }
      this.prorateDetails = {};
      let isProrateCheck = false;
      if (this.productAndSchedules.date_ranges && this.package_type == 1 && this.workshop.workshop_type_id == 1) {
        this.productAndSchedules.date_ranges.forEach((dRange, drIndex) => {
          if (dRange.selectedDays && this.startDate <= dRange.end_date) {
            let last = dRange.selectedDays[dRange.selectedDays.length - 1];
            if (
                this.checkAddButtonStatus(drIndex) ||
                !last.day ||
                !last.day.bit_value ||
                !last.workshop_schedule_id
            ) {
              isProrateCheck = false;
              return;
            } else {
              isProrateCheck = true;
            }
          }
        });
        if (isProrateCheck) {
          let formData = {};
          formData.workshop_program_id = this.programSelected;
          formData.product_id = this.workshopProduct.product_id;
          formData.start_date = this.startDate;
          formData.days = [];
          this.productAndSchedules.date_ranges.forEach((dRange) => {
            if (dRange.selectedDays) {
              dRange.selectedDays.forEach((el) => {
                if (el.day.bit_value && el.workshop_schedule_id) {
                  let day = {};
                  day.bit_value = el.day.bit_value;
                  day.date_range_id = dRange.id;
                  day.workshop_schedule_id = el.workshop_schedule_id;
                  day.start_date = el.day.time.find(
                      (x) => x.workshop_schedule_id == el.workshop_schedule_id
                  ).start_date;
                  day.end_date = el.day.time.find(
                      (x) => x.workshop_schedule_id == el.workshop_schedule_id
                  ).end_date;
                  formData.days.push(day);
                }
              });
            }
          });

          if (
              !formData.workshop_program_id ||
              !formData.product_id ||
              formData.days.length == 0
          )
            return;
          this.showLoader("Price Calculate ...");
          this.$http
              .post(`venues/workshops/schedules/booking/check`, formData)
              .then((response) => {
                if (response.status == 200 && response.data.status == true) {
                  const data = response.data.data;
                  this.prorateDetails = data;
                  if (data.product.allow_pro_rate == 1) {
                    this.workshopProduct.price = data.price;
                  } else {
                    this.prorateDetails.price = this.workshopProduct.price;
                  }
                  this.$forceUpdate();
                  if (this.registerForm.promotion_code) {
                    this.verifyBenefit("promotion");
                  }
                }
                this.hideLoader();
              })
              .catch((error) => {
                this.hideLoader();
                this.prorateDetails = {};
                this.errorChecker(error);
              });
        }
      }
    },
    verifyBenefit(type) {
      if (!this.workshopProduct.product_id) {
        this.showError("Please select the product");
        return;
      }
      let data = {
        products: [],
      };
      if (this.workshopProduct.discount) {
        this.workshopProduct.price = this.workshopProduct.discount.actual_price;
        delete this.workshopProduct.discount;
      }
      this.workshopProduct.workshop_id = this.workshop.id;
      this.workshopProduct.quantity = 1;
      if (type == "promotion") {
        data.promotion_code = this.registerForm.promotion_code;
        if (data.promotion_code == null) {
          this.discountApplied = false;
          return;
        }
      } else {
        data.card_number = this.registerForm.card_number;
      }
      if (this.registerForm.customer_id) {
        data.customer_id = this.registerForm.customer_id;
      }

      data.products = [this.workshopProduct];

      let url = "venues/benefits/verify";
      this.$http
          .post(url, data)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if (data.discount) {
                this.workshopProduct = data.products[0];
                this.workshopProduct.discount = data.discount;
                this.workshopProduct.price = data.price;
                this.$forceUpdate();
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    daysChange(drIndex, index) {
      if (
          this.productAndSchedules.date_ranges[drIndex]["selectedDays"] &&
          this.productAndSchedules.date_ranges[drIndex]["selectedDays"][index].day
              .time.length == 1
      ) {
        this.productAndSchedules.date_ranges[drIndex]["selectedDays"][
            index
            ].workshop_schedule_id = this.productAndSchedules.date_ranges[drIndex][
            "selectedDays"
            ][index].day.time[0].workshop_schedule_id;
        this.$forceUpdate();
        this.checkProrate();
      } else {
        this.$forceUpdate();
        this.checkProrate();
      }
    },

    checkStartDatePassFromEndDateOfClass(item) {
      if (
          moment(this.startDate, "YYYY-MM-DD").isBefore(
              moment(item.end_date, "YYYY-MM-DD")
          )
      ) {
        return false; // return false means do not disable this slot
      } else if (
          moment(this.startDate, "YYYY-MM-DD").isSame(
              moment(item.end_date, "YYYY-MM-DD")
          )
      ) {
        // console.log("start date is equal for slot end date");
        let now = moment();
        let currentDate = now.format("YYYY-MM-DD");
        if (
            moment(currentDate, "YYYY-MM-DD").isSame(
                moment(item.end_date, "YYYY-MM-DD")
            )
        ) {
          // console.log("end date is equal for current date");
          let startDateTime = moment(
              this.startDate + " " + now.format("HH:mm"),
              "YYYY-MM-DD HH:mm"
          ).format("YYYY-MM-DD HH:mm");
          let endDateTime = moment(
              item.end_date + " " + item.end_time,
              "YYYY-MM-DD HH:mm"
          ).format("YYYY-MM-DD HH:mm");
          let result = !moment(startDateTime, "YYYY-MM-DD HH:mm").isBefore(
              moment(endDateTime, "YYYY-MM-DD HH:mm")
          );
          return result;
        } else {
          // console.log("current date is before for slot end date");
          return false;
        }
      } else {
        // console.log("start date is grater than end date slot");
        return true;
      }
    },
    setCardData(data) {
      if (!data.customer_id) {
        this.$set(this.registerForm, "customer_id", null);
      }

      if (!data.name && data.first_name) {
        this.$set(this.registerForm, "name", data.first_name);
      }

      if (!data.customer_id && this.registerForm.name != data.name) {
        this.$set(this.registerForm, "mobile", null);
        this.registerForm.search = null;
        this.registerForm.nameSearch = null;
        this.$set(this.registerForm, "email", null);
        this.$set(this.registerForm, "gender", null);
        this.$set(this.registerForm, "name", null);
        this.$set(this.registerForm, "customer_id", null);
        this.$set(this.registerForm, "first_name", null);
        this.$set(this.registerForm, "image_path", null);
        this.$set(this.registerForm, "dob", null);
        this.$set(this.registerForm, "age_group", null);
        this.$set(this.registerForm, "country_id", null);
        this.$set(this.registerForm, "last_name", null);
        this.$set(this.registerForm, "opt_marketing", false);
        this.$forceUpdate();
      }

      if (data.mobile) {
        this.$set(this.registerForm, "mobile", data.mobile);
      }
      if (data.email) this.$set(this.registerForm, "email", data.email);
      if (data.country_id) {
        this.$set(this.registerForm, "country_id", data.country_id);
      } else {
        this.$set(this.registerForm, "country_id", null);
      }
      if (data.country_id) {
        this.$set(this.registerForm, "id_proof_type_id", data.id_proof_type_id);
      }

      if (data.id_proof_number) {
        this.$set(this.registerForm, "id_proof_number", data.id_proof_number);
      }

      if (data.gender) {
        this.$set(this.registerForm, "gender", data.gender);
      } else {
        this.$set(this.registerForm, "gender", null);
      }
      if (data.dob) {
        this.$set(this.registerForm, "dob", data.dob);
      } else {
        this.$set(this.registerForm, "dob", null);
      }
      if (data.age_group) {
        this.$set(this.registerForm, "age_group", data.age_group);
      } else {
        this.$set(this.registerForm, "age_group", null);
      }

      if (data.image) {
        this.$set(this.registerForm, "image", data.image);
      }

      if (data.name) this.$set(this.registerForm, "name", data.name);
      if (data.last_name) {
        this.$set(this.registerForm, "last_name", data.last_name);
      } else {
        this.$set(this.registerForm, "last_name", null);
      }
      if (data.first_name)
        this.$set(this.registerForm, "first_name", data.first_name);
      if (data.customer_id)
        this.$set(this.registerForm, "customer_id", data.customer_id);
      if (data.image_path) {
        this.$set(this.registerForm, "image_path", data.image_path);
      } else {
        this.$set(this.registerForm, "image_path", null);
      }
      if (data.opt_marketing) {
        if (data.opt_marketing == 1) {
          this.$set(this.registerForm, "opt_marketing", true);
        } else {
          this.$set(this.registerForm, "opt_marketing", false);
        }
      }
      this.$forceUpdate();
    },
    getConfiguration() {
      this.$http
          .get(`venues/workshops/configuration`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if (
                  data.field_document_configurations &&
                  data.field_document_configurations.length !== 0
              ) {
                this.documentFields = data.field_document_configurations;
              } else {
                this.documentFields = []
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    documentUploadRule(field) {
      const rules = [];
      if (field.is_required && !field.isEdit) {
        const rule = (v) => !!v || "Document is required";
        rules.push(rule);
      }
      return rules;
    },

    // packageChange(){
    //   this.workshopProduct.product_id = null;
    //   let program = this.workshop.programs.find(
    //     (x) => x.id == this.programSelected
    //   );
    //   if(program){
    //     this.productAndSchedules.pricing = program.pricing.filter((prod) => prod.package_type == this.package_type);
    //     // this.productAndSchedules.pricing = program.pricing.filters( (prod) => prod.package_type == this.package_type);
    //   }
    // },
  },
};
</script>

<style></style>
