<template>
  <v-dialog v-model="customerSessionFormDialoge" scrollable persistent width="760px">
    <v-form ref="form">
      <v-card>
        <v-card-text class="">
          <div class="row pt-1 border-bottom">
            <div class="col-md-12">
              <div class="d-flex justify-space-between align-center mt-2">
                <SvgIcon class="text-2xl font-semibold" text="Schedule Session" style="color: black" >
                </SvgIcon>
                <v-btn  fab x-small class="shadow-0" @click="close">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
          <v-row>
            <v-col cols="12">
              <v-card
                  flat
                  class="d-flex justify-space-between pa-3 mb-10 shadow-0 bordered"
                  v-if="workshopOrder"
              >
                <div>
                  <div>Name</div>
                  <div class="subtitle-1 font-weight-black blue-text">
                    {{ workshopOrder.customer.first_name }} {{ workshopOrder.customer.last_name }}
                  </div>
                </div>
                <div>
                  <div>Package name</div>
                  <div class="subtitle-1 font-weight-black blue-text">
                    {{ workshopOrder.program_name }} <br>
                    {{  workshopOrder.product_name }}
                  </div>
                </div>
                <div>
                  <div>Duration</div>
                  <div class="subtitle-1 font-weight-black blue-text">
                    {{ workshopOrder.duration }} min
                  </div>
                </div>

                <div>
                  <div>Sessions</div>
                  <div class="subtitle-1 font-weight-black blue-text">
                    {{ workshopOrder.remaining_classes }} / {{ workshopOrder.no_of_class }}
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mt-2" dense>
            <template>
              <v-col
                sm="12"
                class="pb-0 pt-0"
                
              >
                <div>
                  <v-card
                    outlined
                    :style="cardStyle"
                    class="mt-2"
                    v-for="(sd, sdId) in selectedDate" :key="sdId"
                   
                  >
                    <v-card-text>
                      <v-row sm="12">
                        <v-col sm="6">
                          <!-- <label>Select class start date*</label>
                          <date-field
                            :isDateAllowedBool="true"
                            :backFill="checkBackfillPermission($modules.workshops.schedule.slug)"
                            v-model="sd.date"
                            label=""
                            :rules="[(v) => !!v || 'Start date is required']"
                            @change="(val)=>changeDate(val,sdId,'date')"
                            :minDate="workshop.start_date"
                            :maxDate="workshop.end_date"
                            :allowed-dates="allowedDates"
                            class-name="q-text-field shadow-0"
                            :hide-details="true"
                            :dense="true"
                          >
                          </date-field> -->
                          <label>Date*</label>
                          <v-select
                            v-model="sd.date"
                            :items="available_timings"
                            item-text="formated_date"
                            item-value="date"
                            return-object
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            background-color="#fff"
                            class="q-autocomplete shadow-0"
                            hide-details="auto"
                            dense
                            validate-on-blur
                            @change="(val)=>changeDate(val,sdId)"
                          ></v-select>
                        </v-col>
                        <v-col>
                          <label>Select Time slot</label>
                          <v-select
                            v-model="sd.wps_obj"
                            :items="sd.time"
                            item-text="name"
                            item-value="name"
                            return-object
                            outlined
                            :menu-props="{ bottom: true, offsetY: true }"
                            background-color="#fff"
                            class="q-autocomplete shadow-0"
                            hide-details="auto"
                            dense
                            validate-on-blur
                          >
                            <template
                              slot="item"
                              slot-scope="{ item, on, attrs }"
                            >
                              <v-list-item
                                ripple
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-list-item-content>
                                  <v-list-item-title>
                                    {{ item.name }}</v-list-item-title
                                  >
                                  <v-list-item-subtitle>
                                    <div
                                      class="text-trancate"
                                      style="max-width: 100px"
                                    >
                                      {{ item.facility_name || "NA" }}
                                    </div>
                                    <div
                                      class="text-trancate mt-1"
                                      style="max-width: 100px"
                                    >
                                      {{ item.start_date | dateformat }} -to-
                                      {{ item.end_date | dateformat }}
                                    </div>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        fab
                                        x-small
                                        icon
                                      >
                                        <v-icon>mdi-information</v-icon>
                                      </v-btn>
                                    </template>
                                    Trainers :-
                                    {{ item.trainer || "NA" }}
                                  </v-tooltip>
                                </v-list-item-action>
                              </v-list-item>
                            </template>
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-btn
                          fab
                          color="error"
                          @click="removeSession(sdId)"
                          v-if="sdId > 0"
                          absolute
                          top
                          right
                          x-small
                      ><v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </v-card-text>
                  </v-card>
                  <div class="add_btn" v-if="isShowAddBtn">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          color="blue-color"
                          fab
                          x-small
                          dark
                          @click="addSession"
                        >
                          <v-icon>mdi-plus-circle</v-icon>
                        </v-btn>
                      </template>
                      Add Day
                    </v-tooltip>
                  </div>
                </div>
              </v-col>
            </template>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color=""
              class="ma-2  "
              text
              @click="close"
            >Close</v-btn
          >
          <v-btn @click="save" color=" darken-1" class="ma-2 white--text blue-color">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import moment from "moment";
import SvgIcon from "@/components/Image/SvgIcon.vue";
export default {
  components: {SvgIcon},
  props: {
    customerSessionFormDialoge: { type: Boolean, efault: false, },
    orderId: { type: Number, default: null },
    workshop: Object,
  },
  data() {
    return {
      workshopPrograms: [],
      productAndSchedules: {
        products: [],
      },
      workshopBookingId: null,
      registerForm: { document: { id: null }, opt_marketing: false },
      editFlag: false,
      workshopProduct: {
        price: null,
      },
      selectedDays: [{ day: { time: [] } }],
      levels: [],
      schedules: [],
      weekdays: [],
      prorateDetails: {},
      startDate: moment().format("YYYY-MM-DD"),
      package_type:2,
      workshopOrder: { customer:{ first_name: null, last_name: null }}, 
      productId : null,
      selectedDate: [{date: null,wps_obj: null, bit_value: 0, time:[]}],
      allowedDates:[],
      available_timings:[],
      reschedule_timings: [],
      isShowAddBtn: true,

    };
  },
  watch: {
    customerSessionFormDialoge: {
      immediate: true,
      handler(val) {
        if (val) {
          this.reset();
          this.prorateDetails = {};
          if (this.orderId) {
            this.getOrderDetails();
          }
          let date = moment(this.workshop.start_date, "YYYY-MM-DD").isBefore(moment())? moment().format("YYYY-MM-DD"): this.workshop.start_date;
          this.$store.dispatch("loadPromotions", {
            date: date,
            venue_service_id: this.workshop.venue_service_id,
            product_type: "Academy",
          });
          this.workshopProduct = { price: null };
        }
      },
    },
    workshop(val) {
      if (val) {
        this.workshopPrograms = val.programs;
      }
    },
  },
  mounted() {
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
  },
  computed: {
    getWeekDays() {
      return JSON.parse(JSON.stringify(this.$store.getters.getWeekdays.data));
    },
  },
  methods: {
    close() {
      this.workshopOrder = {customer:{ first_name: null, last_name: null }};
      this.$emit("close");
    },

    reset() {
      this.editFlag = false;
      this.workshopOrder = {customer:{ first_name: null, last_name: null }};
      this.registerForm = { document: { id: null }, opt_marketing: false };
      this.workshopProduct = {
        price: null,
      };
      this.productAndSchedules = {
        products: [],
      };
      this.weekdays = [];
      this.selectedDays = [{ day: { time: [] } }];
      setTimeout(() => {
        this.$refs.form.resetValidation();
      });
    },

    checkAddButtonStatus(drIndex = null) {
      if (this.productAndSchedules.date_ranges[drIndex]) {
        if (this.productAndSchedules.date_ranges[drIndex].selectedDays) {
          return (
            this.productAndSchedules.date_ranges[drIndex].selectedDays.length < 10
          );
        } else {
          return false;
        }
      }
    },

    async getOrderDetails() {
      this.showLoader("Loading");
      await this.$http
        .get(`venues/workshops/schedules/booking/session?order_id=${this.orderId}`)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            this.editFlag = true;
            const data = response.data.data;
            this.workshopOrder = data;
            this.workshopBookingId = data.id;
            this.package_type = data.package_type;
            this.productId = data.product_id; 
            this.available_timings =  data.available_timings;
            this.reschedule_timings = data.reschedule_timings;
            let ad = [];
            data.available_timings.forEach((item) => {
              ad.push(item.date);
            })
            this.allowedDates = ad;
            let t = data.available_timings[0];
            this.selectedDate[0].date = t.date;
            this.selectedDate[0].bit_value = t.bit_value;
            this.selectedDate[0].wps_obj = { wps_id: t.wps_ids[0]?t.wps_ids[0]:null, wpr_id:t.reschedule_ids[0]?t.reschedule_ids[0]:null};
            this.changeDate(t,0);
            this.checkAddSessionBtn();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    save() {
      let checkAllSlotFill = true;
      this.selectedDate.forEach((item) => {
        if (!item.date || !item.wps_obj || item.wps_obj.workshop_schedule_id === undefined) {
            checkAllSlotFill = false;
            this.showError("Please select all schedules");
            return;
        }
      });
      if (!this.$refs.form.validate()) {
        this.showError("Please fill all required fields");
        return;
      }
      if (checkAllSlotFill) {
        let formData = new FormData();
        formData.append("workshop_id", this.workshop.id);
        formData.append("workshop_program_id", this.workshopOrder.workshop_program_id);
        formData.append("workshop_type_id", this.workshop.workshop_type_id);
        formData.append("package_type", this.package_type);
        formData.append("venue_service_id", this.workshop.venue_service_id);
        formData.append("order_id", this.orderId);
        formData.append("workshop_booking_id", this.workshopOrder.id);
        formData.append("customer_id", this.workshopOrder.customer_id);
        this.selectedDate.forEach((item, dIndex) => {
            formData.append(`selected_date[${dIndex}][date]`, item.date);
            formData.append(`selected_date[${dIndex}][bit_value]`, item.bit_value);
            formData.append(`selected_date[${dIndex}][wps_id]`, item.wps_obj.workshop_schedule_id);
            formData.append(`selected_date[${dIndex}][wprs_id]`, item.wps_obj.workshop_reschedule_id);
        })
        this.showLoader("Saving..");
        this.$http
          .post(`venues/workshops/schedules/booking/session`, formData, {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          })
          .then((response) => {
            if (response.status == 200 && response.data.status) {
              this.hideLoader();
              this.$emit("complete");
              this.close();
              this.showSuccess("Session booked successfully");
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
      }
    },
    checkStartDatePassFromEndDateOfClass(item) {
      if ( moment(this.startDate, "YYYY-MM-DD").isBefore(moment(item.end_date, "YYYY-MM-DD"))) {
        return false; // return false means do not disable this slot
      } else if (moment(this.startDate, "YYYY-MM-DD").isSame(moment(item.end_date, "YYYY-MM-DD"))) {
        let now = moment();
        let currentDate = now.format("YYYY-MM-DD");
        if (moment(currentDate, "YYYY-MM-DD").isSame(moment(item.end_date, "YYYY-MM-DD"))) {
          let startDateTime = moment(this.startDate + " " + now.format("HH:mm"),"YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
          let endDateTime = moment(item.end_date + " " + item.end_time,"YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
          let result = !moment(startDateTime, "YYYY-MM-DD HH:mm").isBefore(moment(endDateTime, "YYYY-MM-DD HH:mm"));
          return result;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    changeDate(val, index,type = 'id'){
      let wps_ids = [];
      let wpr_ids = [];
      if(type == "id"){
        wps_ids = val.wps_ids;
        wpr_ids = val.reschedule_ids;
        this.selectedDate[index].date = val.date;
        this.selectedDate[index].bit_value = val.bit_value;
      }else{
        let timing = this.available_timings.find( (x) => x.date == val);
        if(timing && timing.wps_ids){
          wps_ids = timing.wps_ids;
          wpr_ids = timing.reschedule_ids;
          this.selectedDate[index].date = val.date;
          this.selectedDate[index].bit_value = val.bit_value;
        }
      }
      if(wps_ids.length == 0 && wpr_ids.length == 0){
        this.showError("Timing not found");
      }else{
        let schedules =[];
        if(wps_ids){
          schedules = this.populateTimings(wps_ids);
        }
        if(wpr_ids && wpr_ids.length > 0){
          wpr_ids.forEach( (wpr_id) => {
            let reschedule = this.reschedule_timings.find( (item) => item.id == wpr_id);
            if(reschedule){
              let obj = {
                trainer: reschedule.first_name+' '+reschedule.last_name,
                facility_name: reschedule.facility_name? reschedule.facility_name: reschedule.location,
                workshop_schedule_id: reschedule.program_schedule_id,
                start_time: reschedule.start_time,
                end_time: reschedule.end_time,
                start_date: reschedule.from_date,
                end_date: reschedule.to_date,
                name:moment(reschedule.start_time, "HH:mm").format("h:mm a") + " To " + moment(reschedule.end_time, "HH:mm").format("h:mm a"),
                workshop_reschedule_id: reschedule.id,
              };
              schedules.push(obj);
            }
         })
        }
        this.selectedDate[index].time = schedules;
      }
    },
    
    populateTimings(wps_ids){
      let filteredSchedules = [];
      let program = this.workshop.programs.find(
        (x) => x.id == this.workshopOrder.workshop_program_id
      );
      program.date_ranges.forEach((dateRange) => {
        dateRange.schedules.forEach((schedule) => {
            if(wps_ids.includes(schedule.id)){
              let obj = {
                trainer: schedule.trainer
                  .map((trainer) => trainer.first_name + trainer.last_name)
                  .join(","),
                facility_name: schedule.facility_name? schedule.facility_name: schedule.location,
                workshop_schedule_id: schedule.id,
                start_time: schedule.start_time,
                end_time: schedule.end_time,
                start_date: dateRange.start_date,
                end_date: dateRange.end_date,
                name:moment(schedule.start_time, "HH:mm").format("h:mm a") + " To " + moment(schedule.end_time, "HH:mm").format("h:mm a"),
                workshop_reschedule_id: null,
              };
              filteredSchedules.push(obj);
            }
        });
      });
      return filteredSchedules;
    },

    addSession() {
        this.selectedDate.push({date: null,wps_obj: null, bit_value: 0, time:[]});
        this.checkAddSessionBtn();
        this.$forceUpdate();
    },

    removeSession(index) {
      if (index >= 0 && index < this.selectedDate.length) {
        this.selectedDate.splice(index, 1); // Remove the entry at the specified index
        this.checkAddSessionBtn();
      }
    },
    
    checkAddSessionBtn(){
      if(this.selectedDate.length < this.workshopOrder.remaining_classes){
        this.isShowAddBtn = true;
      }else{
        this.isShowAddBtn = false;
      }
    }
  },
};
</script>