<template>
  <v-dialog v-model="rescheduleClassDialog" persistent width="50%">
    <v-card class="fill-height">
      <v-card-title class="headline d-flex justify-space-between">
        <div class="text-left d-flex align-center">Reschedule class</div>
        <div class="text-left d-flex align-center">
          Program Name:-{{ programName }}
        </div>

        <div class="text-left d-flex align-center">
          Date:-{{ date | dateformat }}
        </div>
      </v-card-title>

      <v-card-text>
        <v-row class="mt-0">
          <v-col sm="6" md="6">
            <date-field
              :backFill="
                checkBackfillPermission($modules.workshops.schedule.slug)
              "
              v-model="date"
              label="Reschedule date"
            >
            </date-field>
          </v-col>
          <v-col sm="6" md="6">
            <div>
              <v-select
                class="ma-0 pa-0"
                v-model="selectedTimeslot"
                item-text="name"
                label="Time*"
                return-object
                :items="times"
                item-value="times_id"
                outlined
                :menu-props="{ bottom: true, offsetY: true }"
                width="200"
                required
                background-color="#fff"
              >
                <template slot="item" slot-scope="data">
                  <template>
                    <v-list-item-content style="width: 60px">
                      <v-list-item-title>
                        {{ data.item.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ data.item.facility_name }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action style="width: 30px">
                      {{ data.item.attendance }} /
                      {{ data.item.capacity }}
                    </v-list-item-action>
                  </template>
                </template>
              </v-select>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark class="blue-color" @click="close">Close</v-btn>
        <v-btn dark class="teal-color" @click="rescheduleSlot()"
          >Reschedule</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  props: {
    rescheduleClassDialog: { type: Boolean, default: false },
    workshop_booking_schedule_id: { type: Number, default: null },
    workshop_booking_id: { type: Number, default: null },
    workshop_schedule_id: { type: Number, default: null },
    scheduleFromDate: { type: String, default: null },
    programName: { type: String, default: null },
    programId: { type: Number, default: null },
    start_time: { type: String, default: null },
    end_time: { type: String, default: null },
    customer_id: { type: Number, default: null },
    attendance_id: { type: Number, default: null },
    selected_time_slot_id: { type: Number, default: null },
    refreshRescheduleClass: { type: Number, default: null },
  },
  data() {
    return {
      date: this.scheduleFromDate ? this.scheduleFromDate : null,
      times: [],
      selectedTimeslot: null,
    };
  },
  watch: {
    customer_id: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.programId && this.date) {
            this.getTimeSlot();
          }
        }
      },
    },
    refreshRescheduleClass: {
      immediate: true,
      handler(val) {
        if (val) {
          if (this.programId && this.date) {
            this.getTimeSlot();
          }
        }
      },
    },
    scheduleFromDate: {
      immediate: true,
      handler(val) {
        if (val) {
          this.date = val;
        }
      },
    },
    date: {
      immediate: true,
      handler(val) {
        if (val) {
          this.selectedTimeslot = null;
          if (this.programId && this.date && this.customer_id) {
            this.getTimeSlot();
          }
        }
      },
    },
  },
  mounted() {},
  computed: {},
  methods: {
    close() {
      this.$emit("close");
    },

    getTimeSlot() {
      this.times = [];
      this.selectedTimeslot = null;
      var dayName = moment(this.date).format("dddd");
      this.showLoader("Loading...");
      this.$http
        .get(
          `venues/workshops/time_slot?workshop_program_id=${this.programId}&day=${dayName}&date=${this.date}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            if (response.data.data.length) {
              response.data.data.forEach((element, index) => {
                if (
                  element.attendance != element.capacity &&
                  (element.from_date != this.scheduleFromDate ||
                    this.selected_time_slot_id != element.time_slot_id)
                ) {
                  this.times.push({
                    name:
                      moment(element.start_time, "HH:mm").format("h:mm a") +
                      " To " +
                      moment(element.end_time, "HH:mm").format("h:mm a"),
                    time: element.start_time,
                    workshopScheduleId: element.id,
                    is_reschedule: element.is_reschedule,
                    from_date: element.from_date,
                    reschedule_id: element.reschedule_id,
                    facility_name: element.facility_name,
                    capacity: element.capacity,
                    attendance: element.attendance,
                    program_name: element.name,
                    start_time: element.start_time,
                    end_time: element.end_time,
                    times_id: index + 1,
                  });
                }
              });
              this.selectedTimeslot = this.times[0];
            }
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },

    rescheduleSlot() {
      if (!this.selectedTimeslot) {
        this.showError("Please select time!");
        return;
      }
      var reschedule = {
        workshop_booking_id: this.workshop_booking_id,
        workshop_schedule_id: this.selectedTimeslot.workshopScheduleId,
        from_date: this.scheduleFromDate,
        to_date: this.date,
        attendance_id: this.attendance_id,
        start_time: this.selectedTimeslot.start_time,
        end_time: this.selectedTimeslot.end_time,
        reschedule_id: this.selectedTimeslot.reschedule_id,
      };
      this.showLoader("Reschedule class...");
      this.$http
        .post(`venues/workshops/schedules/class-reschedule`, reschedule)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.$emit("reschedule-done");
            this.hideLoader();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style>
</style>