<template>
  <v-container>
    <v-row class=" p-y-6">
      <v-col lg="3" sm="12">
        <BackButton :handler="goToWorkshops"/>
      </v-col>
      <v-col lg="6" sm="12">


        <v-row dense justify="center" no-gutters>

          <v-col class="trainer_container" cols="12">
            <div class="d-flex gap-x-5 justify-center align-center">
              <div style="width: 165px; height: 165px">
                <view-image :height="185" :image="workshop.image_path" :width="185" class="rounded-lg "
                            defaultImage="user"/>
              </div>

              <div class="d-flex justify-start gap-x-6 ml-2">
                <div>
                  <p class="text-xl m-0 font-bold"> {{ workshop.name }} </p>
                  <p class="text-neon ">Type : {{ workshop.workshop_type_id == 1 ? "Program" : "Walk in" }} | Service:
                    {{ workshop.service || "Football" }} </p>
                  <div class="d-flex gap-x-16 mt-6">
                    <div class="stats_data">
                      <p class="text-dark-gray text-base font-normal">Start Date</p>
                      <p class="text-blue font-semibold">

                        {{ workshop.start_date | dateformat }}
                      </p>
                    </div>
                    <div class="stats_data">
                      <p class="text-dark-gray text-base font-normal">End Date</p>
                      <p class="text-blue font-semibold">
                        {{ workshop.end_date | dateformat }}
                      </p>
                    </div>

                  </div>
                  <div class="d-flex gap-x-20 mt-4 ">
                    <div class="stats_data">
                      <p class="text-blue font-semibold">{{ Number(workshop.trainers || 0) }}</p>
                      <p class="text-dark-gray text-base font-normal">Trainers</p>
                    </div>
                    <div class="stats_data">
                      <p class="text-blue font-semibold">{{ Number(workshop.participants || 0) }}</p>
                      <p class="text-dark-gray text-base font-normal">Students</p>
                    </div>
                    <div class="stats_data">
                      <p class="text-blue font-semibold">{{ Number(workshop.sales || 0) | toCurrency }}</p>
                      <p class="text-dark-gray text-base font-normal text-no-wrap">Sales</p>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </v-col>

        </v-row>


      </v-col>
      <v-col class="mt-sm-3  d-sm-flex" lg="3" sm="12">

        <div class="d-flex gap-x-4 ">
          <v-menu
              offset-y
          >
            <template v-slot:activator="{ on, attrs }">



              <v-btn
                  style="background-color: rgba(17, 42, 70, 0.1) ; color:#112A46; min-width: 36px !important; "
                  class=" text_capitalize "
                  outlined
                  v-bind="attrs"
                  v-on="on"

              > <EditBtnIcon/>
                   </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="checkWritePermission($modules.workshops.management.slug) && (workshop.status_id == 1 || workshop.status_id == 11)" @click="editWorkshop">
                <v-list-item-title>Edit</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="
          checkDeletePermission($modules.workshops.management.slug) &&
            workshop.status_id != 12
        "
                           @click="callCancelConfirm({
                id: workshop.id,
                type: workshop.status_id == 1 ? 'cancel' : 'reactivate',
              })">
                <v-list-item-title>{{ workshop.status_id == 1 ? 'Cancel' : 'Reactivate' }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-btn
              v-if="checkWritePermission($modules.workshops.management.slug) && workshop.workshop_type_id == 1"
              class="white--text blue-color"
              color=" darken-1"
              text
              @click="(customerFormDialoge = true), (orderId = null)"
          >
            <AddIcon/>
            <span class="ml-1">Add Customer</span>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="shadow-0">
          <v-card-actions class="text-center ">
            <v-tabs
                v-if="workshop.status != 0"
                v-model="tab"
                background-color="transparent"
                centered
                class="q-tabs-secondary shadow-0 border-bottom"
                light
                slider-color="transparent"
                @change="tabChange"
            >
              <v-tab active-class="active_tab_stroke_icon"
                     href="#details"
              >
                <SvgIcon text="Details">
                  <template v-slot:icon>
                    <PackagesIcon/>
                  </template>
                </SvgIcon>

              </v-tab>

              <v-tab v-if="checkReadPermission($modules.workshops.sales.slug)"
                     active-class="active_tab_stroke_icon"

                     class="d-flex  "
                     href="#sales">
                <SvgIcon text="Sales">
                  <template v-slot:icon>
                    <SalesIcon/>
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab
                  active-class="svg-stroke-neon"
                  class="d-flex  "
                  href="#customer"
                  @click="refresh = !refresh"
              >
                <SvgIcon text="Customers">
                  <template v-slot:icon>
                    <peopleIcon />
                  </template>
                </SvgIcon>
              </v-tab>
              <v-tab
                  active-class="svg-stroke-neon"
                  class="d-flex "
                  href="#customer-analysis"
              >
                <SvgIcon text="Customer Analysis">
                  <template v-slot:icon>
                    <AnalyticsIcon/>
                  </template>
                </SvgIcon>
              </v-tab>

            </v-tabs>
          </v-card-actions>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-tabs-items v-model="tab">
                  <v-tab-item value="details">
                    <v-row>
                      <v-col v-for="(program, index) in workshop.programs" :key="index" class="pointer"
                             md="4" @click="selectProgram(index)">
                        <div :class="workshop.selected_slider === index ? 'program_widget_active' :'program_widget' "
                             class="rounded-5 mb-4 "
                             style="">
                          <v-card-text>
                            <div class="d-flex justify-space-between program_text_white  align-center mb-1">
                              <p class="black-text text-capitalize font-bold p-0 m-0">
                                {{ program.name || "Program name" }}
                              </p>
                              <v-btn icon>
                                <TickIcon class="svg_fill_gray"/>
                              </v-btn>
                            </div>
                            <div class="p-4" style="background-color: #FFFFFF; border-radius: 12px">
                              <div class="d-flex justify-space-between border-bottom">
                                <p class="black-text text-capitalize font-semibold">
                                  Capacity: {{ program.capacity || 20 }}
                                </p>
                                <p class="black-text text-capitalize font-semibold">
                                  Duration : {{ program.duration || 60 }} min
                                </p>
                              </div>
                              <div class="d-flex justify-space-between mt-2">
                                <p class="m-0 p-0">Trainers
                                  <br/> {{ Number(program.total_trainers || 0) | numberFormatter }} </p>
                                <p class="m-0 p-0">Products <br/>
                                  {{ Number(program.total_products || 0) | numberFormatter }} </p>
                                <p class="m-0 p-0">Members <br/> {{ Number(program.members || 0) | numberFormatter }}
                                </p>
                                <p v-if="checkReadPermission($modules.workshops.sales.slug)" class="m-0 p-0">Sales <br/>
                                  {{ Number(program.sales || 0) | numberFormatter }} </p>
                              </div>
                            </div>
                          </v-card-text>
                        </div>
                      </v-col>
                    </v-row>


                    <workshop-details
                        :slider-change="workshop.selected_slider"
                        v-bind="{ workshop: workshop }"
                    ></workshop-details>


                  </v-tab-item>


                  <v-tab-item value="sales">
                    <workshop-sales
                        :exportPermission="checkExportPermission($modules.workshops.management.slug)"
                        :params="params"
                        :showProductType="false"
                        stackType="all"
                    ></workshop-sales>
                  </v-tab-item>
                  <v-tab-item value="customer">
                    <v-container style="padding: 15px">
                      <workshop-customers
                          :refresh="refresh"
                          :slider-change="workshop.selected_slider"
                          :workshopLevels="levels"
                          v-bind="{ workshop: workshop }"
                          @orderId="editOrder"
                          @sessionPopup="sessionPopup"
                          @receipt="showReceipt"
                      ></workshop-customers>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item value="customer-analysis">
                    <customer-analysis
                        :exportPermission="checkExportPermission($modules.workshops.management.slug)"
                        :params="params"
                        :reload="reload"
                        :showProductType="false"
                        stackType="all"
                    ></customer-analysis>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>


    <v-row
        v-if="workshop.workshop_type_id == 1"
        style="margin-top: 20px; margin-bottom: -40px"
    >
      <v-spacer></v-spacer>

    </v-row>

    <customer-form
        :customerFormDialoge="customerFormDialoge"
        :order-id="orderId"
        v-bind="{ workshop: workshop }"
        @close="customerFormDialoge = false"
        @complete="customerschedulecompleted"
    ></customer-form>
    <order-details
        :id="order_id_for_payment"
        :ids="orderIds"
        @close="order_id_for_payment = null; orderIds = null;"
        @paymentDone="paymentDone"
    ></order-details>
    <confirm-model
        v-bind="confirmModel"
        @close="confirmModel.id = null"
        @confirm="confirm"
    ></confirm-model>
    <WorkshopCustomerSessionForm
        :customerSessionFormDialoge="customerSessionFormDialoge"
        :orderId="orderId"
        v-bind="{ workshop: workshop }"
        @close="customerFormDialoge = false;customerSessionFormDialoge=false;"
        @complete="customerSessionBooked"
    />
  </v-container>
</template>

<script>
import OrderDetails from "@/components/Order/OrderDetails.vue";
import CustomerAnalysis from "@/components/Chart/CustomerPieCharts";
import WorkshopDetails from "./WorkshopDetails/WorkshopDetails";

import WorkshopSales from "@/components/Chart/OrderSalesGraph";
import WorkshopCustomers from "./WorkshopDetails/WorkshopCustomerSection.vue";
import CustomerForm from "./WorkshopDetails/CustomerForm";
import BackButton from "@/components/Common/BackButton.vue";
import AddIcon from "@/assets/images/misc/plus-icon.svg";
import SalesIcon from "@/assets/images/memberships/sales-ico.svg";
import AnalyticsIcon from "@/assets/images/events/analytics-icon.svg";
import PackagesIcon from "@/assets/images/memberships/details.svg"
import SvgIcon from "@/components/Image/SvgIcon.vue";
import TickIcon from '@/assets/images/misc/tick-circle.svg';
import EditBtnIcon from "@/assets/images/misc/more-horizontal-square.svg";
import peopleIcon from "@/assets/images/customers/people-icon.svg";
import WorkshopCustomerSessionForm from "./WorkshopDetails/WorkshopCustomerSessionForm.vue";
export default {
  components: {
    EditBtnIcon,
    OrderDetails,
    CustomerAnalysis,
    WorkshopDetails,
    WorkshopSales,
    WorkshopCustomers,
    CustomerForm,
    BackButton,
    AddIcon,
    SalesIcon,
    AnalyticsIcon,
    PackagesIcon,
    SvgIcon,
    TickIcon,
    peopleIcon,
    WorkshopCustomerSessionForm
  },
  data() {
    return {
      levels: [],
      workshop: {
        selected_slider: null,
        documents: [],
        lineups: [],
        tickets: [],
      },
      pWidth: 400,
      tab: "details",
      params: {venue_service_ids: [], product_ids: [], product_type_ids: [4]},
      customerFormDialoge: false,
      orderId: null,
      order_id_for_payment: null,
      refresh: true,
      currentProgramIndex: 0,
      reload: false,
      orderIds: null,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      customerSessionFormDialoge: false,
    };
  },
  mounted() {
    if (typeof this.$route.params.data != "undefined") {
      let data = JSON.parse(atob(this.$route.params.data));
      // console.log(data);
      this.workshop.id = parseInt(data.id);
      this.getWorkshopDetails();
    }
    if (this.$store.getters.getWeekdays.status == false) {
      this.$store.dispatch("loadWeekdays");
    }
    this.onResize();
    this.getLevels();
  },
  methods: {
    editWorkshop() {
      this.$router.push({
        name: "WorkshopEdit",
        params: {data: btoa(this.workshop.id)},
      });
    },

    callCancelConfirm(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${data.type} this academy?`,
        description:
            "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action?",
        type: "academy",
        data: {
          id: data.id,
          type: data.type,
        },
      };
    },
    confirm(data) {
      if (data.type == "academy") {
        this.callCancelWorkshop(data);
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    callCancelWorkshop(data) {
      this.$http
          .put(`venues/workshops/${data.id}/status`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.confirmModel.id = null;
              this.goToWorkshops();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },

    paymentDone() {
      this.refresh = !this.refresh;
      this.getWorkshopDetails(this.workshop.selected_slider);
    },
    customerschedulecompleted(data) {
      this.customerFormDialoge = false;
      if (data) {
        this.order_id_for_payment = data.order_id;
        let ind = this.workshop.programs.findIndex(program => program.id === data.workshop_program_id);
        this.workshop.selected_slider = ind;
      }
      this.refresh = !this.refresh;
      this.getWorkshopDetails(this.workshop.selected_slider);
    },
    customerSessionBooked() {
      this.customerSessionFormDialoge = false;
      this.refresh = !this.refresh;
      this.getWorkshopDetails();
    },

    showReceipt(data) {
      if (typeof data == "object") {
        this.orderIds = data;
      } else {
        this.order_id_for_payment = data;
      }
      this.refresh = !this.refresh;
    },

    tabChange() {
      this.reload = true;
      setTimeout(() => {
        this.reload = false;
      });
      this.$forceUpdate();
    },

    sliderChange() {
      if (
          this.workshop.programs[this.workshop.selected_slider] &&
          this.workshop.programs[this.workshop.selected_slider].pricing.length !=
          0
      ) {

        this.currentProgramIndex = this.workshop.selected_slider;
        this.params = {
          venue_service_ids: [],
          product_ids: [],
          product_type_ids: [4],
        };
        this.workshop.programs[this.workshop.selected_slider].pricing.forEach(
            (product) => {
              this.params.product_ids.push(product.product_id);
            }
        );
        this.reload = true;
        setTimeout(() => {
          this.reload = false;
        });

        this.$forceUpdate();
      }
    },
    getWorkshopDetails(selectedSlider = 0) {
      this.showLoader("Loading");
      this.$http
          .get("venues/workshops/details/" + this.workshop.id)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.workshop = response.data.data;
              this.workshop.programs.forEach((program) => {
                program.total_products = program.pricing.length;
                let totalTrainers = [];
                program.date_ranges.forEach((dRange) => {
                  dRange.schedules.forEach((schedule) => {
                    totalTrainers.push(
                        ...schedule.trainer.map((item) => item.trainer_id)
                    );
                  });
                });
                program.total_trainers = [...new Set(totalTrainers)].length;
              });
              if(selectedSlider >= 0){
                this.workshop.selected_slider = selectedSlider;
              }

              this.sliderChange();
              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    goToWorkshops() {
      this.$router.push({name: "Workshops"}, () => {
      });
    },
    selectProgram(index) {
      this.workshop.selected_slider = index;
      this.sliderChange()
    },
    onResize() {
      if (this.$refs.slidegroup)
        this.pWidth = (this.$refs.slidegroup.$el.clientWidth - 140) / 3;
    },

    editOrder(id) {
      this.orderId = id;
      this.customerFormDialoge = true;
    },

    getLevels() {
      this.$http
          .get(`venues/general/color-codes/workshop`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              this.levels = response.data.data;
              const data = response.data.data;
              if (data.length) {
                this.levels = [];
                this.levels.push({divider: true});
                this.levels.push({header: "General"});
                this.levels.push({divider: true});
                this.levels.push({id: "All", name: "All"});
                this.levels.push({
                  id: -1,
                  name: "Not Applied",
                  color_code: "#555",
                });
                let service = data[0].service;
                this.levels.push({divider: true});
                this.levels.push({header: service});
                this.levels.push({divider: true});
                data.forEach((level) => {
                  if (level.service != service) {
                    this.levels.push({divider: true});
                    this.levels.push({header: level.service});
                    this.levels.push({divider: true});
                    service = level.service;
                  }
                  this.levels.push(level);
                });
              }
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    sessionPopup(orderId){
      this.orderId = orderId;
      this.customerSessionFormDialoge = true;
    }
  },
};
</script>

<style>

.program_widget {
  background-color: rgba(240, 245, 249, 1);

  svg {
    fill: red !important;
    stroke: none !important;
  }
}

.program_widget_active {
  background-color: #4FAEAF;

  .program_text_white {
    p {
      color: #fff !important;
    }
  }

}


.workshopbox {
  background-color: rgba(8, 8, 8, 0.397);
}

.ticketdetail {
  background-color: rgb(218, 218, 218);
  padding: 5px;
}

.tableHeader {
  background-color: #062b48 !important;
  color: #fff !important;
}

.v-tab {
  min-width: 150px;
}

.tab_active {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.section {
  margin: 20px 0 50px 0;
  padding: 20px 0 20px;
}

.chart_ic {
  background-image: url("../../assets/images/nav_icon/graph.png");
  padding: 10px;
  background-size: contain;
}


.stats_data {
  word-wrap: break-word;

  p {
    margin: 0;
    padding: 0;
  }

}

.v-slide-group__content {
  column-gap: 2rem !important;
}
</style>
