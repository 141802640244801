<template>
  <v-card
      :loading="loader"
      class="mx-auto pa-2 pt-0 shadow rounded-5"
      loader-height="8"
      max-width="100%"
      outlined
  >
    <v-container fluid no-gutters>
      <div class="d-flex justify-space-between align-center">
        <h4>
          <SvgIcon :text="heading">
            <template v-slot:icon>
              <FlagIcon/>
            </template>
          </SvgIcon>
        </h4>
        <div>

          <v-btn
              v-if="exportPermission"
              class="export-button"
              elevation="0"
              small
              @click="exportToCSV"
          >
            <SvgIcon text="Export">
              <template v-slot:icon>
                <ExportIcon/>
              </template>
            </SvgIcon>
          </v-btn>
          <v-btn
              absolute
              elevation="1"
              fab
              right
              top
              x-small
              @click="fullscreen"
          >
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="dialog">
      <v-card>
        <v-toolbar color="rgb(0, 176, 175)" dark>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ heading }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn dark icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <column-chart
              :class="type"
              :colors="colors"
              :data="chartData"
              :dataset="{ borderWidth: 0 }"
              :library="chartOptions"
              :stacked="true"
              :xtitle="xTitle"
              :ytitle="yTitle"
              height="600px"
              legend="bottom"
          ></column-chart>
        </v-card-text>
      </v-card>
    </v-dialog>
    <column-chart
        :colors="colors"
        :data="chartData"
        :dataset="{ borderWidth: 0 }"
        :library="chartOptions"
        :stacked="true"
        :ytitle="yTitle"
        legend="bottom"
    ></column-chart>
  </v-card>
</template>

<script>
import FlagIcon from '@/assets/images/misc/flag-icon.svg'
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from '@/assets/images/misc/export-icon.svg'

export default {
  components: {SvgIcon, FlagIcon, ExportIcon},
  props: {
    type: String,
    heading: String,
    chartData: Array,
    yTitle: String,
    xTitle: String,
    exportData: Array,
    loader: {type: Boolean, default: true},
    colors: {
      type: Array,
      default: function () {
        return [
          "rgb(44, 59, 70)",
          "rgb(0, 177, 176)",
          "rgb(255, 192, 0)",
          "rgb(123, 127, 129)",
          "rgb(126, 110, 77)",
          "rgb(14, 103, 171)",
          "rgb(109, 139, 163)",
          "rgb(44, 76, 70)",
          "rgb(0, 134, 176)",
          "rgb(255, 152, 0)",
          "rgb(123, 187, 129)",
          "rgb(126, 53, 77)",
          "rgb(14, 87, 171)",
          "rgb(109, 39, 163)",
          "rgb(23, 46, 70)",
          "rgb(43, 67, 176)",
          "rgb(255, 43, 0)",
          "rgb(23, 43, 129)",
          "rgb(233, 45, 77)",
          "rgb(234, 103, 171)",
          "rgb(109, 154, 163)",
          "rgb(32, 76, 70)",
          "rgb(32, 134, 176)",
          "rgb(34, 34, 0)",
          "rgb(123, 23, 129)",
          "rgb(32, 53, 77)",
          "rgb(87, 45, 171)",
          "rgb(198, 45, 34)",
          "rgb(32, 21, 124)",
          "rgb(0, 43, 21)",
          "rgb(56, 192, 89)",
          "rgb(123, 21, 43)",
          "rgb(12, 110, 45)",
          "rgb(14, 54, 171)",
          "rgb(23, 139, 76)",
          "rgb(44, 76, 70)",
          "rgb(0, 134, 67)",
          "rgb(255, 34, 0)",
          "rgb(123, 187, 12)",
          "rgb(34, 53, 77)",
          "rgb(14, 45, 76)",
          "rgb(34, 39, 34)",
          "rgb(23, 56, 70)",
          "rgb(43, 23, 176)",
          "rgb(255, 76, 0)",
          "rgb(23, 23, 129)",
          "rgb(233, 45, 77)",
          "rgb(234, 32, 171)",
          "rgb(109, 154, 163)",
          "rgb(32, 67, 70)",
          "rgb(32, 79, 176)",
          "rgb(34, 34, 0)",
          "rgb(123, 23, 129)",
          "rgb(32, 87, 77)",
          "rgb(5, 245, 171)",
          "rgb(198, 34, 34)",
        ];
      },
    },
    showCategory: {type: Boolean, default: true},

    heighest: {type: Number},
    exportPermission: {type: Boolean, default: true},
  },
  watch: {
    heighest(val) {
      this.chartOptions.scales.yAxes[0].ticks.suggestedMax = val;
    },
    categories(val) {
      this.category = val;
    },
  },
  mounted() {
    this.category = this.categories;
  },
  data() {
    return {
      category: [],

      dialog: false,
      fullscreenFlag: false,
      fullscreenBtn: true,
      chartOptions: {
        legend: {
          labels: {
            filter: function (item) {
              return !item.text.includes("Total");
            },
          },
        },
        tooltips: {enabled: true},
        scales: {
          yAxes: [
            {
              ticks: {
                suggestedMax: 2,
              },
            },
          ],
          xAxes: [
            {
              id: "xAxis1",
              type: "category",
              ticks: {
                callback: function (label) {
                  return (
                      (label.split(" ")[1] ? label.split(" ")[1] : "") +
                      " " +
                      (label.split(" ")[2] ? label.split(" ")[2] : "") +
                      " " +
                      (label.split(" ")[3] ? label.split(" ")[3] : "")
                  );
                },
              },
            },
            {
              id: "xAxis2",
              type: "category",
              offset: true,
              gridLines: {
                display: false,
                drawOnChartArea: false,
                drawBorder: false,
                lineWidth: 0.1,
              },
              ticks: {
                callback: function (label) {
                  return label.split(" ")[0];
                },
                scaleBeginAtZero: false,
                autoSkip: false,
              },

              scaleLabel: {
                display: true,
                labelString: this.xTitle,
                fontStyle: "bold",
              },
            },
          ],
        },
        plugins: {
          datalabels: {
            display: function (context) {
              return context.dataset.data[context.dataIndex] !== 0;
            },
            formatter: function (value, context) {
              if (context.dataset.label == "Bookings") {
                let data = new Intl.NumberFormat("en-US", {
                  maximumSignificantDigits: 4,
                }).format(value);
                return data + (value > 1 ? " bookings" : " booking");
                // return data;
              } else if (context.dataset.label == "Sales") {
                var formatter = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: context.dataset.currency,
                  minimumFractionDigits: 0,
                });
                return formatter.format(value);
              } else {
                return new Intl.NumberFormat("en-US", {
                  maximumSignificantDigits: 4,
                }).format(value);
              }
            },
            color: "#fff",
            align: "center",
            anchor: "center",
            font: {
              size: 12,
              weight: 700,
            },
            offset: 8,
          },
        },
      },
    };
  },

  computed: {
    categories() {
      return [];
    },
    selectedAllCategory() {
      return this.category.length === this.categories.length;
    },
    selctedSomeCategory() {
      return this.category.length > 0 && !this.selectedAllCategory;
    },
    icon() {
      if (this.selectedAllCategory) return "mdi-close-box";
      if (this.selctedSomeCategory) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    emitCategoryChange() {
      this.$emit("categoryChange", this.category);
    },
    fullscreen() {
      this.dialog = true;
    },
    closeEvent() {
      this.dialog = false;
    },
    exportToCSV() {
      var csvData = Object.keys(this.exportData[0]).reverse().join(",") + "\n";
      this.exportData.forEach((item) => {
        csvData += Object.values(item).reverse().join(",");
        csvData += "\n";
      });
      let anchor = document.createElement("a");
      anchor.href = "data:text/csv;charset=utf-8," + encodeURI(csvData);
      anchor.target = "_blank";
      anchor.download = this.heading + ".csv";
      anchor.click();
    },
  },
};
</script>
<style scoped>
.graph-title {
  text-align: center;
  color: #066a8c;
  font-size: 20px;
  padding: 10px;
}
</style>
