<template>
  <v-container fluid>
    <v-row class="mt-3">
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <div class="d-flex gap-x-5">
            <v-select v-if="showProductType"
                      placeholder="Product Type"
                      required
                      v-model="productTypeIds"
                      outlined
                      background-color="#fff"
                      item-value="id"
                      item-text="name"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :items="productTypes"
                      @change="callGraph"
                      multiple
                      class="q-autocomplete shadow-0"
                      hide-details
                      dense
                      style="max-width: 180px !important;"

            >
              <template
                  v-if="productTypeIds.length === productTypes.length"
                  v-slot:selection="{ index }"
              >
                <span v-if="index === 0">All</span>
              </template>
              <template v-else v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-elepsis">{{ item.name }}</span>
                <span v-if="index === 1" class="text-elepsis">, ..</span>
              </template>
            </v-select>
            <v-select v-if="showVenueService"
                      placeholder="Service"
                      required
                      v-model="venueServiceIds"
                      outlined
                      background-color="#fff"
                      item-value="venue_service_id"
                      item-text="name"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :items="venueServices"
                      @change="callGraph"
                      multiple
                      class="q-autocomplete shadow-0"
                      hide-details
                      dense
                      style="max-width: 180px !important;"
            >
              <template
                  v-if="venueServiceIds.length === venueServices.length"
                  v-slot:selection="{ index }"
              >
                <span v-if="index === 0">All</span>
              </template>
              <template v-else v-slot:selection="{ item, index }">
                <span v-if="index === 0" class="text-elepsis">{{ item.name }}</span>
                <span v-if="index === 1" class="text-elepsis">, ..</span>
              </template>
            </v-select>
          </div>

          <div class="d-flex gap-x-5">
            <v-btn-toggle
                borderless
                class="q-tabs shadow-0 bordered"
                style="height: 40px !important;"
                v-model="dateToggle"
                :mandatory="mandatory"
                tile
            >
              <v-btn value="DATE" height="40">Day</v-btn>
              <v-btn value="MONTH" height="40">Month</v-btn>
              <v-btn value="YEAR" height="40">Year</v-btn>
            </v-btn-toggle>

          </div>

          <date-range-field
              :dateType="dateToggle"
              :date1="date1"
              :date2="date2"
              @periodChange="dateChange"
              class-name="q-text-field shadow-0"
              :outlined="true"
              :dense="true"
          />

        </div>

      </v-col>

      <v-col cols="12"  v-if="showProductType">
        <v-radio-group
            v-if="showProductType"
            v-model="stack"
            row
            style="margin-top: -10px"
            class=""
            label="Stack type"

        >
          <v-radio label="All" value="all"></v-radio>
          <v-radio label="Product Type" value="type"></v-radio>
          <v-radio label="Service" value="service"></v-radio>
          <v-radio label="Sales Channel" value="source"></v-radio>
        </v-radio-group>
      </v-col>

    </v-row>
    <v-row>
      <v-col cols="12">
        <BarChart
          :exportPermission="exportPermission"
          v-bind="bookings"
        ></BarChart>
      </v-col>

      <v-col cols="12">
        <BarChart
          :exportPermission="exportPermission"
          v-bind="sales"
        ></BarChart>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BarChart from "./BarChart";
import moment from "moment";
import DateRangeField from "@/components/Fields/DateRangeField.vue";
export default {
  components: {
    DateRangeField,
    BarChart,
  },
  props: {
    params: {
      type: Object,
      default: () => {
        return { venue_service_ids: [], product_ids: [], product_type_ids: [] };
      },
    },
    "export-permission": { type: Boolean, default: true },
    "show-venue-service": { type: Boolean, default: true },
    "show-product-type": { type: Boolean, default: false },
    "stack-type": { type: String, default: "all" }, //service,type,all
    reload: { type: Boolean, default: false },
  },
  watch: {
    params: {
      immediate: true,
      handler(params) {
        if (params.venue_service_ids.length > 0) {
          this.venueServiceIds = params.venue_service_ids;
        }
        if (params.product_type_ids.length > 0) {
          this.productTypeIds = params.product_type_ids;
        }
        if (params.product_ids.length > 0) {
          this.productIds = params.product_ids;
        }
        if (params.sales_team_ids != undefined) {
          if (params.sales_team_ids.length > 0) {
            this.salesTeamIds = params.sales_team_ids;
          }
        }
      },
    },
    stackType: {
      immediate: true,
      async handler() {
        await this.getGraphStartDate();
        this.callGraph();
      },
    },
    stack(val) {
      this.$emit("stackChange", val);
    },
    reload: {
      immediate: true,
      handler(val) {
        if (val) {
          this.callGraph();
        }
      },
    },
  },
  data() {
    return {
      salesTeamIds: [],
      agePieChart: null,
      genderPieChart: null,
      countryPieChart: null,
      dateToggle: "DATE",
      venueServiceIds: [],
      productTypeIds: [],
      productIds: [],
      date1: moment()
        .subtract(15, "days")
        .format("YYYY-MM-DD"),
      date2: moment().format("YYYY-MM-DD"),
      bookings: {
        heading: "Bookings",
        type: "totalBooking",
        fullscreenFlag: true,
        yTitle: "Bookings",
        xTitle: "Dates",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      sales: {
        heading: "Sales",
        type: "totalSales",
        fullscreenFlag: true,
        yTitle: "Sales",
        xTitle: "Dates",
        chartData: [],
        exportData: [],
        loader: true,
        heighest: 0,
      },
      stack: "all",
      mandatory: false,
    };
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  methods: {
    callGraph() {
      this.getOrderSalesGraphData();
      this.getOrderBookingGraphData();
    },
    async getGraphStartDate() {
      let url = "?type=sales";
      url += this.getApiFilter();
      await this.$http
          .get(`venues/graphs/get-start-date${url}`)
          .then((response) => {
            if (response.status == 200) {
              this.date1 = moment(response.data.data).subtract(2,'days').format("YYYY-MM-DD");
              this.date2 = moment(response.data.data).add(13,'days').format("YYYY-MM-DD");
              this.$forceUpdate()
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    dateChange(data) {
      this.date1 = data.date1;
      this.date2 = data.date2;
      this.callGraph();
    },
    getOrderSalesGraphData() {
      this.sales.loader = true;
      let url = "?type=sales";
      url += this.getApiFilter();
      this.$http
        .get(`venues/graphs/orders${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            let chartData = this.getGraphData(data, "Sales");
            this.sales.heighest = Math.ceil(chartData.largest * 1.1);
            this.sales.chartData = chartData.data;
            this.sales.exportData = data;
            this.sales.loader = false;
            this.mandatory = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getOrderBookingGraphData() {
      this.bookings.loader = true;
      let url = "?type=bookings";
      url += this.getApiFilter();
      this.$http
        .get(`venues/graphs/orders${url}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data.data;
            let chartData = this.getGraphData(data, "Bookings");
            this.bookings.heighest = Math.ceil(chartData.largest * 1.1);
            this.bookings.chartData = chartData.data;
            this.bookings.exportData = data;
            this.bookings.loader = false;
            this.mandatory = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getApiFilter() {
      let url = `&date_type=${this.dateToggle.toLowerCase()}&start_date=${
        this.date1
      }&end_date=${this.date2}&stack_type=${this.stackType}`;

      if (
        this.venueServiceIds.length != this.venueServices.length &&
        this.venueServiceIds.length > 0
      ) {
        url += `&${this.venueServiceIds
          .map((n, index) => `venue_service_ids[${index}]=${n}`)
          .join("&")}`;
      }

      if (
        this.productTypeIds.length != this.productTypes.length &&
        this.productTypeIds.length > 0
      ) {
        url += `&${this.productTypeIds
          .map((n, index) => `product_type_ids[${index}]=${n}`)
          .join("&")}`;
      }

      if (this.productIds.length > 0) {
        url += `&${this.productIds
          .map((n, index) => `product_ids[${index}]=${n}`)
          .join("&")}`;
      }

      if (this.salesTeamIds.length > 0) {
        url += `&${this.salesTeamIds
          .map((n, index) => `sales_team_ids[${index}]=${n}`)
          .join("&")}`;
      }

      return url;
    },
    getGraphData(data, type) {
      var graphData = [];
      let largest = 0;
      if (data.length > 0) {
        let keys = Object.keys(data[0]).filter(
          (ele) => ele.toLowerCase() != "date"
        );
        let keysLength = keys.length;
        data.forEach((element, index) => {
          let di = 0;
          let lar = 0;
          for (let i = 0; i <= keysLength; i++) {
            if (index == 0) {
              graphData[i] = {};
              graphData[i].data = {};
              graphData[i].name = keys[i];
              if (element[keys[i]]) {
                lar = lar + parseInt(element[keys[i]]);
              }
              graphData[i].data[element.Date] = element[keys[i]];
              graphData[keysLength] = {};
              graphData[keysLength].name = "Total";
              graphData[keysLength].data = {};
              graphData[keysLength].data[element.Date] = 0;
              di = di + 1;
            } else {
              if (element[keys[i]]) {
                lar = lar + parseInt(element[keys[i]]);
              }
              graphData[i].data[element.Date] = element[keys[i]];
              di = di + 1;
            }
          }
          graphData[keysLength].data[element.Date] = 0;
          if (largest < lar) {
            largest = lar;
          }
        });
        if (keysLength == 1 && keys[0] == type) {
          graphData[0].library = {};
          graphData[0].library.datalabels = {
            color: "rgba(0,0,49)",
            align: "end",
            anchor: "end",
            font: {
              size: 12,
              weight: 700,
            },
          };
        }
        if (this.stackType != "all") {
          graphData[keysLength].library = {};
          graphData[keysLength].library.datalabels = {
            color: function() {
              return "rgba(0,0,49)";
            },
            formatter: (value, ctx) => {
              let total = ctx.chart.$totalizer.totals[ctx.dataIndex];
              if (type == "Bookings") {
                let data = new Intl.NumberFormat("en-US", {
                  maximumSignificantDigits: 4,
                }).format(total);
                return data + (total > 1 ? " bookings" : " booking");
              } else if (type == "Sales") {
                var formatter = new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: this.$store.getters.currencyCode,
                  minimumFractionDigits: 0,
                });
                return formatter.format(total);
              } else {
                return new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: this.$store.getters.currencyCode,
                  // maximumSignificantDigits: 3,
                }).format(total);
              }
            },
            font: {
              size: 12,
              weight: 700,
            },
            align: "end",
            anchor: "end",
            display: function(ctx) {
              return (
                ctx.datasetIndex === ctx.chart.$totalizer.utmost &&
                ctx.chart.$totalizer.totals[ctx.dataIndex] !== 0
              );
            },
          };
        }
      }
      return { data: graphData, largest: largest };
    },
  },
};
</script>

<style>
/* TODO:: Move to global once design completed */
.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn{
  border-color: unset !important;
}

.text-elepsis {
  white-space: nowrap; /* Prevents the text from wrapping */
  text-overflow: ellipsis; /* Adds ellipsis (...) to indicate text overflow */
  max-width: 85%;
  overflow: hidden;
}
</style>